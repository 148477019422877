import { Grid } from 'antd';

export const formLayout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 20 },
  colon: false,
  labelAlign: 'left',
  layout: 'horizontal',
};

export function useInputStyle() {
  const { lg: isDesktop } = Grid.useBreakpoint();

  return {
    styleHalf: {
      style: {
        width: isDesktop ? '50%' : '100%',
      },
    },
  };
}

export const styleHalf = {
  style: { width: '50%' },
};

export const styleFull = {
  style: { width: '100%' },
};

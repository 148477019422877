import { createElement } from 'react';

const newlineRegex = /(\r\n|\r|\n)/g;

export default function nl2br(text) {
  if (typeof text === 'undefined' || text === null) {
    return '';
  }

  return text.split(newlineRegex).map((line, index) => {
    if (line.match(newlineRegex)) {
      return createElement('br', { key: index });
    }
    return line;
  });
}

import { useCurrentUser } from '../reducers/user';
import { Navigate } from 'react-router-dom';
import React from 'react';

export default function PrivateRoute({ element: Component, ...rest }) {
  const user = useCurrentUser();

  if (!user) {
    return <Navigate to="/login" replace />;
  }

  return React.cloneElement(Component, rest);
}
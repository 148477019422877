import { Card, Grid } from 'antd';

export default function ResponsiveCard({ children, ...props }) {
  const { lg: isDesktop } = Grid.useBreakpoint();

  return (
    <Card bordered={false} size={isDesktop ? 'default' : 'small'} {...props}>
      {children}
    </Card>
  );
}

import { Avatar, Space, Tabs, Skeleton } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { withPage } from '../../components/Page';
import ResponsiveCard from '../../components/ResponsiveCard';
import Contact from './Contact';
import Education from './Education';
import Skills from './Skills';
import Job from './Job';
import Conditions from './Conditions';
import Salary from './Salary';
import Priorities from './Priorities';
import Others from './Others';
import useFetchProfile from './hooks/fetch-profile';
import { useOptions } from '../../reducers/options';
import { patchProfile as patchProfileAPI } from '../../api/profile';
import useTabState from '../../hooks/useTabState';

function Index({ reportError }) {
  const { isOptionEmpty } = useOptions();
  const { profile, patchProfile, profileLoading } = useFetchProfile({
    onError: reportError,
  });
  const { activeKey, handleTabChange } = useTabState(
    '#basic-info',
    '#conditions'
  );

  const submit = async values => {
    const response = await patchProfileAPI(values).catch(e => e.response);

    if (response.status === 200) {
      patchProfile(values);
    } else {
      reportError(response.status);
    }
  };

  if (isOptionEmpty()) {
    return null;
  }

  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      <ResponsiveCard>
        <h1 className="page-title">
          <Avatar icon={<UserOutlined />} />
          <span>プロフィール</span>
        </h1>
        <Tabs
          activeKey={activeKey}
          style={{ overflow: 'initial' }}
          onChange={handleTabChange}
        >
          <Tabs.TabPane key="1" tab="基本情報">
            <Skeleton active loading={profileLoading}>
              <Space className="fluid" direction="vertical" size="large">
                <Contact data={profile} onSubmit={submit} />
                <Education data={profile} onSubmit={submit} />
                <Skills data={profile} onSubmit={submit} />
              </Space>
            </Skeleton>
          </Tabs.TabPane>
          <Tabs.TabPane key="2" tab="希望条件">
            <Skeleton active loading={profileLoading}>
              <Space className="fluid" direction="vertical" size="large">
                <Job data={profile} onSubmit={submit} />
                <Conditions data={profile} onSubmit={submit} />
                <Salary data={profile} onSubmit={submit} />
                <Priorities data={profile} onSubmit={submit} />
                <Others data={profile} onSubmit={submit} />
              </Space>
            </Skeleton>
          </Tabs.TabPane>
        </Tabs>
      </ResponsiveCard>
    </Space>
  );
}

export default withPage(Index);

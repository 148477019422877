import { useEffect } from 'react';
import {
  Space,
  Form,
  Input,
  Select,
  Row,
  Col,
  Grid,
  Typography,
} from 'antd';
import classNames from 'classnames';
import Definitions from '../../components/Definitions';
import EditableCard from '../../components/EditableCard';
import { useOptions } from '../../reducers/options';
import { formLayout } from '../../helpers/form';
import useInput from '../../hooks/input';
import { pick, pluck } from '../../helpers/objects';
import Pipe from '../../components/Pipe';
import useEditable from '../../hooks/editable';
import useValidation from '../../hooks/validation';

const { TextArea } = Input;
const { Text } = Typography;

const INPUT_NAMES = [
  { name: 'hrbcId', rules: false },
  {
    name: 'employmentType',
    rules: ['required'],
  },
  { name: 'employmentTypeOther', rules: false },
  {
    name: 'workStart',
    rules: [['requiredUnless', { with: 'workStartOther' }]],
  },
  { name: 'workStartOther', rules: false },
  {
    name: 'servicePeriod',
    rules: ['required'],
  },
  { name: 'servicePeriodOther', rules: false },
  { name: 'overtime', rules: false },
  { name: 'noOvertime', rules: false },
  { name: 'overtimeOther', rules: false },
  { name: 'holidayWork', rules: false },
  { name: 'holidayWorkOther', rules: false },
  { name: 'shiftWork', rules: false },
  { name: 'shiftWorkOther', rules: false },
  { name: 'commute', rules: false },
];

export default function Conditions({ data, onSubmit }) {
  const { isEdit, toggleEdit, sending, send } = useEditable();

  if (!data) return null;

  const inputs = pick(data, pluck(INPUT_NAMES, 'name'));

  const submit = value => send(() => onSubmit(value));

  return (
    <EditableCard
      isEdit={isEdit()}
      onToggle={toggleEdit}
      sending={sending}
      title="希望勤務条件"
      readBlock={<ReadBlock data={inputs} />}
      editBlock={
        <EditBlock data={inputs} onSubmit={submit} toggleEdit={toggleEdit} />
      }
    />
  );
}

function EditBlock({ data, onSubmit, toggleEdit }) {
  const { lg: isDesktop } = Grid.useBreakpoint();
  const { loopOptions, getOptionId } = useOptions();
  const { input, patchInput, inputProps } = useInput(data);
  const { error, validate } = useValidation(INPUT_NAMES);

  const employmentTypeOtherId = getOptionId('employmentType', 'その他')
  const servicePeriodOtherId = getOptionId('servicePeriod', 'その他')
  const holidayWorkOtherId = getOptionId('holidayWork', 'その他')
  const shiftWorkOtherId = getOptionId('shiftWork', 'その他')

  useEffect(() => {
    const employmentTypeOtherIndex = INPUT_NAMES.findIndex(input => input.name === 'employmentTypeOther');

    if (input.employmentType.includes(employmentTypeOtherId)) {
      INPUT_NAMES[employmentTypeOtherIndex].rules = ['required'];
    } else {
      INPUT_NAMES[employmentTypeOtherIndex].rules = false
    }
  }, [input.employmentType])

  useEffect(() => {
    const servicePeriodOtherIndex = INPUT_NAMES.findIndex(input => input.name === 'servicePeriodOther');

    if (input.servicePeriod.includes(servicePeriodOtherId)) {
      INPUT_NAMES[servicePeriodOtherIndex].rules = ['required'];
    } else {
      INPUT_NAMES[servicePeriodOtherIndex].rules = false
    }
  }, [input.servicePeriod])

  useEffect(() => {
    const holidayWorkOtherIndex = INPUT_NAMES.findIndex(input => input.name === 'holidayWorkOther');

    if (input.holidayWork === holidayWorkOtherId) {
      INPUT_NAMES[holidayWorkOtherIndex].rules = ['required'];
    } else {
      INPUT_NAMES[holidayWorkOtherIndex].rules = false
    }
  }, [input.holidayWork])

  useEffect(() => {
    const shiftWorkOtherIndex = INPUT_NAMES.findIndex(input => input.name === 'shiftWorkOther');

    if (input.shiftWork === shiftWorkOtherId) {
      INPUT_NAMES[shiftWorkOtherIndex].rules = ['required'];
    } else {
      INPUT_NAMES[shiftWorkOtherIndex].rules = false
    }
  }, [input.shiftWork])

  const handleFinish = () => validate(input, () => onSubmit(input));

  return (
    <Form onFinish={handleFinish} {...formLayout}>
      <Form.Item label="就業形態" required>
        <Space
          direction="vertical"
          size={12}
          className={classNames(['fluid', error.className('employmentType')])}
        >
          <Select
            mode="multiple"
            showArrow
            allowClear
            value={input.employmentType}
            onChange={val => patchInput({ employmentType: val })}
            placeholder="選択してください　※複数選択可"
          >
            {loopOptions('employmentType', (val, label) => (
              <Select.Option key={val} value={val}>
                {label}
              </Select.Option>
            ))}
          </Select>
          <Space className="fluid" direction="vertical" size={2}>
            <Space>
              <Text>「その他」は具体的希望を記入</Text>
              <Text type="secondary">（上記未選択の場合 必須）</Text>
            </Space>
            <TextArea
              {...inputProps('employmentTypeOther')}
              autoSize={{ minRows: 2 }}
            />
          </Space>
        </Space>
        {error.render('employmentType', '就業形態')}
        {error.render('employmentTypeOther', '「その他」は具体的希望')}
      </Form.Item>
      <Form.Item label="就業開始" required>
        <Space
          direction="vertical"
          size={12}
          className={classNames(['fluid', error.className('workStart')])}
        >
          <Space className="fluid">
            <Input
              {...inputProps('workStart')}
              style={{ width: 200 }}
              placeholder="例)　2024年4月1日"
            />
            <span>～可能</span>
          </Space>
          <Space className="fluid" direction="vertical" size={2}>
            <Space>
              <Text>その他希望</Text>
              <Text type="secondary">（〇月開始または〇カ月後）</Text>
            </Space>
            <TextArea
              {...inputProps('workStartOther')}
              autoSize={{ minRows: 2 }}
            />
          </Space>
        </Space>
        {error.render('workStart', '就業開始')}
      </Form.Item>
      <Form.Item label="就業期間" required>
        <Space
          className={classNames(['fluid', error.className('servicePeriod')])}
          direction="vertical"
          size={12}
        >
          <Select
            mode="multiple"
            showArrow
            allowClear
            value={input.servicePeriod}
            onChange={val => patchInput({ servicePeriod: val })}
            placeholder="選択してください　※複数選択可"
          >
            {loopOptions('servicePeriod', (val, label) => (
              <Select.Option key={val} value={val}>
                {label}
              </Select.Option>
            ))}
          </Select>
          <Space className="fluid" direction="vertical" size={2}>
            <Space>
              <Text>「その他」は具体的希望を記入</Text>
              {/* <Text type="secondary">（上記未選択の場合 必須）</Text> */}
            </Space>
            <TextArea
              {...inputProps('servicePeriodOther')}
              autoSize={{ minRows: 2 }}
            />
          </Space>
        </Space>
        {error.render('servicePeriod', '就業期間')}
        {error.render('servicePeriodOther', '「その他」は具体的希望')}
      </Form.Item>
      <Form.Item label="残業">
        <Space className="fluid" direction="vertical" size={isDesktop ? 12 : 0}>
          <Row gutter={isDesktop ? 12 : [12, 12]}>
            <Col lg={12} span={24}>
              <Input
                {...inputProps('overtime')}
                addonBefore="月"
                addonAfter="時間まで残業可"
                placeholder="例)　10"
              />
            </Col>
          </Row>
          <Row gutter={isDesktop ? 12 : [12, 12]}>
            <Col lg={12} span={24}>
              <span>残業不可曜日</span>
              <Select
                mode="multiple"
                showArrow
                allowClear
                value={input.noOvertime}
                onChange={val => patchInput({ noOvertime: val })}
                placeholder="選択してください　※複数選択可"
              >
                {loopOptions('weekday', (val, label) => (
                  <Select.Option key={val} value={val}>
                    {label}
                  </Select.Option>
                ))}
              </Select>
            </Col>
          </Row>
          <Space className="fluid" direction="vertical" size={2}>
            <Text>その他希望</Text>
            <TextArea
              {...inputProps('overtimeOther')}
              autoSize={{ minRows: 2 }}
            />
          </Space>
        </Space>
      </Form.Item>
      <Form.Item label="休日出勤">
        <Space className="fluid" direction="vertical" size={12}>
          <Select
            value={input.holidayWork}
            onChange={val => patchInput({ holidayWork: val })}
            placeholder="選択してください"
          >
            {loopOptions('holidayWork', (val, label) => (
              <Select.Option key={val} value={val}>
                {label}
              </Select.Option>
            ))}
          </Select>
          <Space className="fluid" direction="vertical" size={2}>
            <Text>「その他」は具体的希望を記入</Text>
            <TextArea
              {...inputProps('holidayWorkOther')}
              autoSize={{ minRows: 2 }}
            />
            {error.render('holidayWorkOther', '「その他」は具体的希望')}
          </Space>
        </Space>
      </Form.Item>
      <Form.Item label="シフト勤務">
        <Space className="fluid" direction="vertical" size={12}>
          <Select
            value={input.shiftWork}
            onChange={val => patchInput({ shiftWork: val })}
            placeholder="選択してください"
          >
            {loopOptions('shiftWork', (val, label) => (
              <Select.Option key={val} value={val}>
                {label}
              </Select.Option>
            ))}
          </Select>
          <Space className="fluid" direction="vertical" size={2}>
            <Text>「その他」は具体的希望を記入</Text>
            <TextArea
              {...inputProps('shiftWorkOther')}
              autoSize={{ minRows: 2 }}
            />
            {error.render('shiftWorkOther', '「その他」は具体的希望')}
          </Space>
        </Space>
      </Form.Item>
      <Form.Item label="勤務地・通勤">
        <TextArea
          {...inputProps('commute')}
          placeholder="例）東急東横線　通勤60分以内"
          autoSize={{ minRows: 2 }}
        />
      </Form.Item>
      <div className="mt-lg">
        <EditableCard.Footer onCancel={toggleEdit} />
      </div>
    </Form>
  );
}

function ReadBlock({ data }) {
  const { getOptionLabel } = useOptions();

  return (
    <Definitions>
      <Definitions.Item title="就業形態">
        {data.employmentType.length > 0 && (
          <p>
            {data.employmentType
              .map(employmentType =>
                getOptionLabel('employmentType', employmentType)
              )
              .join(', ')}
          </p>
        )}
        {data.employmentTypeOther && <p>{data.employmentTypeOther}</p>}
      </Definitions.Item>
      <Definitions.Item title="就業開始">
        {data.workStart && (
          <p>{data.workStart}～可能</p>
        )}
        {data.workStartOther && <p>{data.workStartOther}</p>}
      </Definitions.Item>
      <Definitions.Item title="就業期間" fluid>
        {data.servicePeriod.length > 0 && (
          <p>
            {data.servicePeriod
              .map(servicePeriod => {
                return getOptionLabel('servicePeriod', servicePeriod);
              })
              .join(', ')}
          </p>
        )}
        {data.servicePeriodOther && <p>{data.servicePeriodOther}</p>}
      </Definitions.Item>
      <Definitions.Item title="残業" fluid>
        {(data.overtime || data.noOvertime.length > 0) && (
          <Space split={<Pipe />}>
            {data.overtime && <span>{data.overtime}時間／月まで残業可</span>}
            {data.noOvertime.length > 0 && (
              <span>
                残業不可曜日：
                {data.noOvertime
                  .map(noOvertime => {
                    return getOptionLabel('weekday', noOvertime);
                  })
                  .join(', ')}
              </span>
            )}
          </Space>
        )}
        {data.overtimeOther && <p className="mt-sm">{data.overtimeOther}</p>}
      </Definitions.Item>
      <Definitions.Item title="休日出勤" fluid>
        {data.holidayWork && (
          <p>{getOptionLabel('holidayWork', data.holidayWork)}</p>
        )}
        {data.holidayWorkOther && <p>{data.holidayWorkOther}</p>}
      </Definitions.Item>
      <Definitions.Item title="シフト勤務" fluid>
        {data.shiftWork && <p>{getOptionLabel('shiftWork', data.shiftWork)}</p>}
        {data.shiftWorkOther && <p>{data.shiftWorkOther}</p>}
      </Definitions.Item>
      <Definitions.Item title="勤務地・通勤" fluid>
        {data.commute}
      </Definitions.Item>
    </Definitions>
  );
}

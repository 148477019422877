import { Card, Form, Input, DatePicker, InputNumber, Select } from 'antd';
import moment from 'moment';
import { useOptions } from '../../reducers/options';
import { monthFormat } from '../../helpers/format';

const { TextArea } = Input;
const { RangePicker } = DatePicker;
const { OptGroup, Option } = Select;

const officeworkNames = [
  'オフィスワーク/事務系',
  '管理系',
  '営業系',
  'サポート系',
  '販売/サービス系',
  'コンサルタント系',
  '建築/土木系',
  '各種オペレーター/倉庫/設備系',
  '金融系',
  '経営者',
];

export default function ProjectHistoryForm({
  type,
  value,
  index,
  error,
  onChange,
}) {
  const { options, loopOptions, loopOptionItems } = useOptions();

  return (
    <Card>
      <Form.Item label="企業名/プロジェクト名">
        <Input
          value={value.name}
          onChange={e => onChange({ name: e.target.value })}
        />
      </Form.Item>
      <Form.Item
        label="開始〜終了年月"
        required
        validateStatus={error.when(`histories.${index}.startAt`, 'error')}
      >
        <RangePicker
          picker="month"
          format={monthFormat}
          value={[
            value.startAt ? moment(value.startAt) : null,
            value.endAt ? moment(value.endAt) : null,
          ]}
          onChange={dates =>
            onChange({
              startAt: dates ? dates[0].format('YYYY-MM') : '',
              endAt: dates ? dates[1].format('YYYY-MM') : '',
            })
          }
          className="fluid-mobile"
        />
      </Form.Item>
      <Form.Item label="業種">
        <Select
          value={value.industry}
          onChange={value => onChange({ industry: value })}
        >
          {loopOptions('industry', (id, name) => (
            <Select.Option key={id} value={id}>
              {name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      {type === 'OFFICE' && (
        <Form.Item label="職種">
          <Select
            value={value.occupation}
            onChange={value => onChange({ occupation: value })}
          >
            {officeworkNames.map(name => (
              <OptGroup key={name} label={name}>
                {loopOptionItems('occupations', name, (id, name) => (
                  <Option key={id} value={id}>
                    {name}
                  </Option>
                ))}
              </OptGroup>
            ))}
          </Select>
        </Form.Item>
      )}
      <Form.Item
        label="担当業務"
        required
        validateStatus={error.when(`histories.${index}.description`, 'error')}
      >
        <TextArea
          autoSize={{ minRows: 3 }}
          value={value.description}
          onChange={e => onChange({ description: e.target.value })}
        />
      </Form.Item>
      {type === 'TECH' && (
        <Form.Item label="作業工程">
          <Select
            mode="multiple"
            allowClear
            value={value.processes}
            onChange={value => onChange({ processes: value })}
          >
            {loopOptions('projectProcesses', (id, name) => (
              <Select.Option key={id} value={id}>
                {name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      )}
      <Form.Item label={type === 'TECH' ? 'OS/言語/DB' : 'OA'}>
        <TextArea
          autoSize={{ minRows: 3 }}
          value={value.environments}
          onChange={e => onChange({ environments: e.target.value })}
        />
      </Form.Item>
      <Form.Item label="機器/アプリ/ツール">
        <TextArea
          autoSize={{ minRows: 3 }}
          value={value.tools}
          onChange={e => onChange({ tools: e.target.value })}
        />
      </Form.Item>
      <Form.Item label="人数">
        <InputNumber
          min={1}
          formatter={value => `${value}人`}
          value={value.members}
          onChange={value => onChange({ members: value })}
          className="fluid-mobile"
        />
      </Form.Item>
      <Form.Item label="役割">
        <Select
          value={value.role}
          onChange={value => onChange({ role: value })}
        >
          {options['projectRoles']
            .filter(o => o.type === type)
            .map(item => (
              <Select.Option key={item.id} value={item.id}>
                {item.name}
              </Select.Option>
            ))}
        </Select>
      </Form.Item>
      <Form.Item label="規模" style={{ marginBottom: 0 }}>
        <TextArea
          autoSize={{ minRows: 3 }}
          value={value.scale}
          onChange={e => onChange({ scale: e.target.value })}
        />
      </Form.Item>
    </Card>
  );
}

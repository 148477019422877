import axios from 'axios';

const apiKey = process.env.REACT_APP_GOOGLE_API_KEY;
const baseUrl = 'https://maps.googleapis.com';

export function geoCoding(address) {
  const params = {
    address,
    language: 'ja',
    region: 'JP',
    key: apiKey,
  };

  return axios.get(`${baseUrl}/maps/api/geocode/json`, { params });
}

import { Avatar, Tabs, Space } from 'antd';
import { ProfileOutlined } from '@ant-design/icons';
import React from 'react';
import { withPage } from '../../components/Page';
import ResponsiveCard from '../../components/ResponsiveCard';
import WebForm from './WebForm';
import Upload from './Upload';
import useTabState from '../../hooks/useTabState';

function Resume({ reportError }) {
  const { activeKey, handleTabChange } = useTabState(
    '#web-form',
    '#attachments'
  );

  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      <ResponsiveCard>
        <h1 className="page-title">
          <Avatar icon={<ProfileOutlined />} />
          <span>職務経歴書</span>
        </h1>
        <Tabs
          activeKey={activeKey}
          style={{ overflow: 'initial' }}
          onChange={handleTabChange}
        >
          <Tabs.TabPane key="1" tab="Webフォーム">
            <WebForm onError={reportError} />
          </Tabs.TabPane>
          <Tabs.TabPane key="2" tab="アップロード・ダウンロード">
            <Upload onError={reportError} />
          </Tabs.TabPane>
        </Tabs>
      </ResponsiveCard>
    </Space>
  );
}

export default withPage(Resume);

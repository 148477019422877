import { message } from 'antd';
import save from 'save-file';
import { downloadFile } from '../../../api/resume';

export async function fileDownload(attachmentId, fileName) {
  try {
    const response = await downloadFile(attachmentId);
    if (response.status === 200) {
      await save(response.data, fileName);
    } 
  } catch (error) {
    if (error.response.status === 404) {
      message.error('ファイルが見つかりませんでした');
    } else {
      message.error('ファイルのダウンロードに失敗しました。');
    }
  }
}

import { useCurrentUser } from '../reducers/user';
import { Navigate } from 'react-router-dom';
import React from 'react';

export default function GuestRoute({ element: Component, ...rest }) {
  const user = useCurrentUser();

  if (user) {
    return <Navigate to="/" replace={true} />;
  }

  return React.cloneElement(Component, rest);
}

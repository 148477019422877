import { createSlice } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';

const { reducer, actions } = createSlice({
  name: 'user',
  initialState: null,
  reducers: {
    setUser(state, action) {
      return action.payload;
    }
  }
});

export default reducer;

export const { setUser } = actions;

export function useCurrentUser() {
  return useSelector(state => state.user);
}

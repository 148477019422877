import { useState } from 'react';
import {
  Space,
  Form,
  Input,
  Radio,
  Select,
  DatePicker,
  Button,
  Row,
  Col,
  Grid,
  Typography,
  Popover,
} from 'antd';
import {
  PlusOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons';
import moment from 'moment';
import Definitions from '../../components/Definitions';
import Pipe from '../../components/Pipe';
import EditableCard from '../../components/EditableCard';
import { useOptions } from '../../reducers/options';
import { styleFull, useInputStyle, formLayout } from '../../helpers/form';
import { dateFormat } from '../../helpers/format';
import { pick, pluck } from '../../helpers/objects';
import { useContactInput } from './hooks/update-contact';
import useEditable from '../../hooks/editable';
import useValidation from '../../hooks/validation';

const { Text } = Typography;

const INPUT_NAMES = [
  { name: 'hrbcId', rules: false },
  { name: 'railway', rules: ['required'] },
  { name: 'station', rules: ['required'] },
  { name: 'minutes', rules: ['required'] },
  { name: 'name', rules: ['required'] },
  { name: 'nameKana', rules: ['required'] },
  { name: 'gender', rules: ['required'] },
  { name: 'birthday', rules: ['required'] },
  { name: 'zipCode', rules: ['required'] },
  { name: 'address', rules: ['required'] },
  { name: 'residentialForm', rules: false },
  { name: 'nearestRailway1', rules: ['required'] },
  { name: 'nearestStation1', rules: ['required'] },
  { name: 'nearestTransportation1', rules: ['required'] },
  { name: 'nearestTransportationMinutes1', rules: ['required'] },
  { name: 'nearestRailway2', rules: false },
  { name: 'nearestStation2', rules: false },
  { name: 'nearestTransportation2', rules: false },
  { name: 'nearestTransportationMinutes2', rules: false },
  { name: 'tel', rules: ['required'] },
  { name: 'tel2', rules: false },
  { name: 'email', rules: ['required', 'email'] },
  { name: 'email2', rules: ['email'] },
];

export default function Contact({ data, onSubmit }) {
  const { isEdit, toggleEdit, sending, send } = useEditable();

  if (!data) return null;

  const inputs = pick(data, pluck(INPUT_NAMES, 'name'));

  const submit = value => send(() => onSubmit(value));

  return (
    <EditableCard
      isEdit={isEdit()}
      onToggle={toggleEdit}
      sending={sending}
      title="連絡先"
      readBlock={<ReadBlock data={inputs} />}
      editBlock={
        <EditBlock data={inputs} onSubmit={submit} toggleEdit={toggleEdit} />
      }
    />
  );
}

function EditBlock({ data, onSubmit, toggleEdit }) {
  const { lg: isDesktop } = Grid.useBreakpoint();
  const { styleHalf } = useInputStyle();
  const { loopOptions } = useOptions();
  const {
    input,
    patchInput,
    patchZipCode,
    addressNotFound,
    makePatch,
    inputProps,
  } = useContactInput(data);
  const { error, validate } = useValidation(INPUT_NAMES);

  const [showNearestStation2, setShowNearestStation2] = useState(
    input.nearestRailway2 &&
      input.nearestStation2 &&
      input.nearestTransportation2 &&
      input.nearestTransportationMinutes2
  );

  const clearNearestStation2 = () => {
    patchInput({
      nearestRailway2: '',
      nearestStation2: '',
      nearestTransportation2: '',
      nearestTransportationMinutes2: '',
    });
    setShowNearestStation2(false);
  };

  const handleFinish = () => validate(input, () => onSubmit(input));

  return (
    <Form onFinish={handleFinish} {...formLayout}>
      <Form.Item label="氏名（漢字）" required>
        <Row gutter={8}>
          <Col span={isDesktop ? 12 : 24} className={error.className('name')}>
            <Input {...inputProps('name')} className="mb-sm-mobile" />
            {error.render('name', '氏名（漢字）')}
          </Col>
        </Row>
      </Form.Item>
      <Form.Item label="氏名（フリガナ）" required>
        <Row gutter={8}>
          <Col
            span={isDesktop ? 12 : 24}
            className={error.className('nameKana')}
          >
            <Input {...inputProps('nameKana')} className="mb-sm-mobile" />
            {error.render('nameKana', '氏名（フリガナ）')}
          </Col>
        </Row>
      </Form.Item>
      <Form.Item
        label="性別"
        required
        validateStatus={error.when('gender', 'error')}
      >
        <Radio.Group {...inputProps('gender')}>
          {loopOptions('gender', (key, val) => (
            <Radio key={key} value={key}>
              {val}
            </Radio>
          ))}
        </Radio.Group>
        {error.render('gender', '性別')}
      </Form.Item>
      <Form.Item
        label="生年月日"
        required
        validateStatus={error.when('birthday', 'error')}
      >
        <DatePicker
          value={input.birthday ? moment(input.birthday) : null}
          onChange={val =>
            patchInput({
              birthday: val ? val.format('YYYY-MM-DD') : '',
            })
          }
          dropdownClassName="hide-footer"
          format={dateFormat}
          {...styleHalf}
        />
        {error.render('birthday', '生年月日')}
      </Form.Item>
      <Form.Item label="郵便番号" required>
        <Row gutter={8}>
          <Col
            span={isDesktop ? 12 : 24}
            className={error.className('zipCode')}
          >
            <Popover
              title={null}
              content={
                <Space>
                  <Text type="warning">
                    <ExclamationCircleOutlined />
                  </Text>
                  <span>住所が見つかりませんでした。</span>
                </Space>
              }
              open={addressNotFound}
              placement={isDesktop ? 'right' : 'bottom'}
            >
              <Input
                {...inputProps('zipCode')}
                className="mb-sm-mobile"
                addonBefore="〒"
                maxLength={7}
                value={input.zipCode}
                onChange={patchZipCode}
              />
            </Popover>
            {error.render('zipCode', '郵便番号')}
          </Col>
        </Row>
        <div className="mt-sm">
          <Text type="secondary">
            住所が自動で入力されます。ハイフン（-）は不要です。
          </Text>
        </div>
      </Form.Item>
      <Form.Item
        label="住所"
        required
        validateStatus={error.when('address', 'error')}
      >
        <Input.TextArea
          {...inputProps('address')}
          placeholder="例）東京都渋谷区渋谷1-14-16 渋谷野村證券ビル8F"
          autoSize={{ minRows: 2 }}
        />
        {error.render('address', '住所')}
      </Form.Item>
      <Form.Item label="住居形態">
        <Select
          value={input.residentialForm}
          onChange={val => patchInput({ residentialForm: val })}
          placeholder="選択してください"
        >
          {loopOptions('residentialForm', (val, label) => (
            <Select.Option key={val} value={val}>
              {label}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item label="最寄駅" required>
        <Space direction="vertical" className="fluid" size="small">
          <Input.Group>
            <Row gutter={[8, 8]}>
              <Col
                lg={12}
                span={24}
                className={error.className('nearestRailway1')}
              >
                <Input
                  value={input.nearestRailway1}
                  onChange={makePatch('nearestRailway1')}
                  addonBefore="路線"
                  placeholder="例）JR山手線"
                />
                {error.render('nearestRailway1', '最寄駅 路線')}
              </Col>
              <Col
                lg={12}
                span={24}
                className={error.className('nearestStation1')}
              >
                <Input
                  value={input.nearestStation1}
                  onChange={makePatch('nearestStation1')}
                  suffix="駅"
                />
                {error.render('nearestStation1', '最寄駅 駅名')}
              </Col>
              <Col
                lg={12}
                span={24}
                className={error.className('nearestTransportation1')}
              >
                <Select
                  value={input.nearestTransportation1}
                  onChange={val => patchInput({ nearestTransportation1: val })}
                  placeholder="選択してください"
                  {...styleFull}
                >
                  {loopOptions('nearestTransportation1', (val, label) => (
                    <Select.Option key={val} value={val}>
                      {label}
                    </Select.Option>
                  ))}
                </Select>
                {error.render('nearestTransportation1', '最寄駅 交通手段')}
              </Col>
              <Col
                lg={12}
                span={24}
                className={error.className('nearestTransportationMinutes1')}
              >
                <Input
                  value={input.nearestTransportationMinutes1}
                  onChange={makePatch('nearestTransportationMinutes1')}
                  placeholder="半角数字　例)　5"
                  suffix="分"
                />
                {error.render(
                  'nearestTransportationMinutes1',
                  '最寄駅 所要時間'
                )}
              </Col>
            </Row>
          </Input.Group>
          {!showNearestStation2 && (
            <Button
              type="dashed"
              onClick={() => setShowNearestStation2(true)}
              icon={<PlusOutlined />}
              block
            >
              最寄駅を追加する
            </Button>
          )}
        </Space>
      </Form.Item>
      {showNearestStation2 && (
        <Form.Item label="最寄駅 2">
          <Space direction="vertical" className="fluid" size="small">
            <Input.Group>
              <Row gutter={[8, 8]}>
                <Col lg={12} span={24}>
                  <Input
                    value={input.nearestRailway2}
                    onChange={makePatch('nearestRailway2')}
                    addonBefore="路線"
                    placeholder="例）JR山手線"
                  />
                </Col>
                <Col lg={12} span={24}>
                  <Input
                    value={input.nearestStation2}
                    onChange={makePatch('nearestStation2')}
                    suffix="駅"
                  />
                </Col>
                <Col lg={12} span={24}>
                  <Select
                    value={input.nearestTransportation2}
                    onChange={val =>
                      patchInput({ nearestTransportation2: val })
                    }
                    placeholder="選択してください"
                    {...styleFull}
                  >
                    {loopOptions('nearestTransportation2', (val, label) => (
                      <Select.Option key={val} value={val}>
                        {label}
                      </Select.Option>
                    ))}
                  </Select>
                </Col>
                <Col lg={12} span={24}>
                  <Input
                    value={input.nearestTransportationMinutes2}
                    onChange={makePatch('nearestTransportationMinutes2')}
                    placeholder="半角数字　例)　5"
                    suffix="分"
                  />
                </Col>
              </Row>
            </Input.Group>
            <Button
              type="dashed"
              onClick={clearNearestStation2}
              icon={<DeleteOutlined />}
              block
            >
              最寄駅 2をクリアする
            </Button>
          </Space>
        </Form.Item>
      )}
      <Form.Item label="電話番号" required>
        <Input.Group>
          <Row gutter={isDesktop ? 8 : [8, 8]}>
            <Col lg={12} span={24} className={error.className('tel')}>
              <Input addonBefore="1." {...inputProps('tel')} />
              {error.render('tel', '電話番号 1.')}
            </Col>
            <Col lg={12} span={24}>
              <Input addonBefore="2." {...inputProps('tel2')} />
            </Col>
          </Row>
        </Input.Group>
        <div className="mt-sm">
          <Text type="secondary">電話番号 1. のみ必須です。</Text>
        </div>
      </Form.Item>
      <Form.Item label="メールアドレス" required>
        <Input.Group>
          <Row gutter={isDesktop ? 8 : [8, 8]}>
            <Col lg={12} span={24} className={error.className('email')}>
              <Input addonBefore="1." {...inputProps('email')} />
              {error.render('email', 'メールアドレス 1.')}
            </Col>
            <Col lg={12} span={24} className={error.className('email2')}>
              <Input addonBefore="2." {...inputProps('email2')} />
              {error.render('email2', 'メールアドレス 2.')}
            </Col>
          </Row>
        </Input.Group>
        <div className="mt-sm">
          <Text type="secondary">メールアドレス 1. のみ必須です。</Text>
        </div>
      </Form.Item>
      <div className="mt-lg">
        <EditableCard.Footer onCancel={toggleEdit} />
      </div>
    </Form>
  );
}

function ReadBlock({ data }) {
  const { getOptionLabel } = useOptions();
  const { lg: isDesktop } = Grid.useBreakpoint();

  const name = (() => {
    let name = data.name;
    if (data.nameKana) {
      name += `（${data.nameKana}）`;
    }
    return name;
  })();

  return (
    <Definitions>
      <Definitions.Item
        title={
          <span>
            氏名
            <Pipe />
            性別
          </span>
        }
      >
        <Space
          split={isDesktop ? <Pipe /> : null}
          direction={isDesktop ? 'horizontal' : 'vertical'}
        >
          {name && <span>{name}</span>}
          <span>{getOptionLabel('gender', data.gender)}</span>
        </Space>
      </Definitions.Item>
      <Definitions.Item title="生年月日">
        {moment(data.birthday).format(dateFormat)}
      </Definitions.Item>
      <Definitions.Item
        title={
          <span>
            郵便番号
            <Pipe />
            住所
          </span>
        }
        fluid
      >
        {data.zipCode && (
          <>
            〒{data.zipCode}
            <br />
          </>
        )}
        {data.address}
      </Definitions.Item>
      <Definitions.Item title="住居形態" fluid>
        {getOptionLabel('residentialForm', data.residentialForm)}
      </Definitions.Item>
      <Definitions.Item title="最寄駅" fluid>
        <Space split={<Pipe />}>
          <span>
            {data.nearestRailway1} {data.nearestStation1}駅{' '}
            {getOptionLabel(
              'nearestTransportation1',
              data.nearestTransportation1
            )}
            {data.nearestTransportationMinutes1}分
          </span>
          {data.nearestRailway2 !== '' && (
            <span>
              {data.nearestRailway2} {data.nearestStation2}駅{' '}
              {getOptionLabel(
                'nearestTransportation2',
                data.nearestTransportation2
              )}
              {data.nearestTransportationMinutes2}分
            </span>
          )}
        </Space>
      </Definitions.Item>
      <Definitions.Item title="電話番号" fluid>
        <Space
          split={isDesktop ? <Pipe /> : null}
          direction={isDesktop ? 'horizontal' : 'vertical'}
        >
          <span>{data.tel}</span>
          {data.tel2 ? <span>{data.tel2}</span> : null}
        </Space>
      </Definitions.Item>
      <Definitions.Item title="メールアドレス" fluid>
        <Space
          split={isDesktop ? <Pipe /> : null}
          direction={isDesktop ? 'horizontal' : 'vertical'}
        >
          <span>{data.email}</span>
          {data.email2 ? <span>{data.email2}</span> : null}
        </Space>
      </Definitions.Item>
    </Definitions>
  );
}

import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
  Card,
  Row,
  Col,
  Form,
  Input,
  Button,
  Space,
  Divider,
  Typography,
} from 'antd';
import queryString from 'query-string';
import { withPage } from '../components/Page';
import SiteName from '../components/SiteName';
import { setUser } from '../reducers/user';
import { fetchUser } from '../api/user';
import { login } from '../api/auth';

const { Paragraph } = Typography;

function Login({ reportError }) {
  const [mistake, setMistake] = useState(false);
  const [loading, setLoading] = useState(false);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const submit = async () => {
    if (username === '' || password === '') return false;

    setMistake(false);
    setLoading(true);

    const data = { username, password, remember: true };
    const { status } = await login(data).catch(err => err.response);

    if (status !== 200) {
      setLoading(false);
      if (status >= 500) {
        reportError(status);
      } else {
        setMistake(true);
      }
      return false;
    }

    const response = await fetchUser().catch(err => err.response);

    if (response.status !== 200) {
      setLoading(false);
      reportError(response.status);
      return false;
    }

    // 求人サイトからの遷移であれば、元の URL に戻る
    const query = queryString.parse(window.location.search);
    if (typeof query.to !== 'undefined') {
      window.location.href = query.to;
    } else {
      dispatch(setUser(response.data));
      navigate('/profile');
    }
  };

  return (
    <Row justify="center">
      <Col lg={12} span={24}>
        <Card
          bordered={false}
          className="layout-header"
          bodyStyle={{ width: `100%` }}
        >
          <Row justify="center">
            <Col span={24}>
              <SiteName homeLink={false} />
            </Col>
          </Row>
        </Card>
        <Card bordered={false}>
          {mistake && (
            <Paragraph type="danger">
              ユーザーIDまたはパスワードが誤っています。
            </Paragraph>
          )}
          <Form layout="vertical" onFinish={submit}>
            <Form.Item label="ユーザーID">
              <Input
                value={username}
                onChange={e => setUsername(e.target.value)}
              />
            </Form.Item>
            <Form.Item label="パスワード">
              <Input.Password
                value={password}
                onChange={e => setPassword(e.target.value)}
              />
            </Form.Item>
            <Button type="primary" htmlType="submit" block loading={loading}>
              ログイン
            </Button>
            <Divider />
            <Space direction="vertical" size={16} className="fluid">
              <Link to="/forget-username" className="ant-btn ant-btn-block">
                ユーザーIDをお忘れの方はこちら
              </Link>
              <Link to="/forget-password" className="ant-btn ant-btn-block">
                パスワードをお忘れの方はこちら
              </Link>
            </Space>
          </Form>
        </Card>
      </Col>
    </Row>
  );
}

export default withPage(Login, { simple: true });

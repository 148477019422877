import { Space, Form, Input, InputNumber, Row, Col, Grid } from 'antd';
import Definitions from '../../components/Definitions';
import EditableCard from '../../components/EditableCard';
import { styleFull, formLayout } from '../../helpers/form';
import { formatNumber } from '../../helpers/format';
import useInput from '../../hooks/input';
import { pick } from '../../helpers/objects';
import Pipe from '../../components/Pipe';
import useEditable from '../../hooks/editable';

const INPUT_NAMES = [
  'hrbcId',
  'salaryNowHour',
  'salaryNowMonth',
  'salaryNowYear',
  'salaryRequestHour',
  'salaryRequestMonth',
  'salaryRequestYear',
  'salaryLimitHour',
  'salaryLimitMonth',
  'salaryLimitYear',
];

export default function Salary({ data, onSubmit }) {
  const { isEdit, toggleEdit, sending, send } = useEditable();

  if (!data) return null;

  const inputs = pick(data, INPUT_NAMES);

  const submit = value => send(() => onSubmit(value));

  return (
    <EditableCard
      isEdit={isEdit()}
      onToggle={toggleEdit}
      sending={sending}
      title="給与"
      readBlock={<ReadBlock data={inputs} />}
      editBlock={
        <EditBlock data={inputs} onSubmit={submit} toggleEdit={toggleEdit} />
      }
    />
  );
}

function EditBlock({ data, onSubmit, toggleEdit }) {
  const { lg: isDesktop } = Grid.useBreakpoint();
  const { input, inputProps } = useInput(data);

  return (
    <Form onFinish={() => onSubmit(input)} {...formLayout}>
      <p>※半角数字で入力してください</p>
      <Form.Item label="現在の給与">
        <Row gutter={isDesktop ? 12 : [12, 12]}>
          <Col lg={8} span={24}>
            <InputNumber
              {...inputProps('salaryNowHour')}
              {...styleFull}
              addonBefore="時給"
              addonAfter="円"
              controls={false}
              placeholder="例） 1500"
            />
          </Col>
          <Col lg={8} span={24}>
            <InputNumber
              {...inputProps('salaryNowMonth')}
              {...styleFull}
              addonBefore="月給"
              addonAfter="円"
              controls={false}
              placeholder="例） 240000"
            />
          </Col>
          <Col lg={8} span={24}>
            <InputNumber
              {...inputProps('salaryNowYear')}
              {...styleFull}
              addonBefore="年収"
              addonAfter="円"
              controls={false}
              placeholder="例） 3000000"
            />
          </Col>
        </Row>
      </Form.Item>
      <Form.Item label="希望の給与">
        <Row gutter={isDesktop ? 12 : [12, 12]}>
          <Col lg={8} span={24}>
            <InputNumber
              {...inputProps('salaryRequestHour')}
              {...styleFull}
              addonBefore="時給"
              addonAfter="円"
              controls={false}
              placeholder="例） 1500"
            />
          </Col>
          <Col lg={8} span={24}>
            <InputNumber
              {...inputProps('salaryRequestMonth')}
              {...styleFull}
              addonBefore="月給"
              addonAfter="円"
              controls={false}
              placeholder="例） 240000"
            />
          </Col>
          <Col lg={8} span={24}>
            <InputNumber
              {...inputProps('salaryRequestYear')}
              {...styleFull}
              addonBefore="年収"
              addonAfter="円"
              controls={false}
              placeholder="例） 3000000"
            />
          </Col>
        </Row>
      </Form.Item>
      <Form.Item label="給与下限">
        <Row gutter={isDesktop ? 12 : [12, 12]}>
          <Col lg={8} span={24}>
            <InputNumber
              {...inputProps('salaryLimitHour')}
              {...styleFull}
              addonBefore="時給"
              addonAfter="円"
              controls={false}
              placeholder="例） 1500"
            />
          </Col>
          <Col lg={8} span={24}>
            <InputNumber
              {...inputProps('salaryLimitMonth')}
              {...styleFull}
              addonBefore="月給"
              addonAfter="円"
              controls={false}
              placeholder="例） 240000"
            />
          </Col>
          <Col lg={8} span={24}>
            <InputNumber
              {...inputProps('salaryLimitYear')}
              {...styleFull}
              addonBefore="年収"
              addonAfter="円"
              controls={false}
              placeholder="例） 3000000"
            />
          </Col>
        </Row>
      </Form.Item>
      <div className="mt-lg">
        <EditableCard.Footer onCancel={toggleEdit} />
      </div>
    </Form>
  );
}

function ReadBlock({ data }) {
  const { lg: isDesktop } = Grid.useBreakpoint();

  return (
    <Definitions>
      <Definitions.Item title="現在の給与" fluid>
        <Space
          split={isDesktop ? <Pipe /> : null}
          direction={isDesktop ? 'horizontal' : 'vertical'}
        >
          <span>時給 {formatNumber(data.salaryNowHour)} 円</span>
          <span>月給 {formatNumber(data.salaryNowMonth)} 円</span>
          <span>年収 {formatNumber(data.salaryNowYear)} 円</span>
        </Space>
      </Definitions.Item>
      <Definitions.Item title="希望の給与" fluid>
        {(data.salaryRequestHour ||
          data.salaryRequestMonth ||
          data.salaryRequestYear) && (
            <Space
              split={isDesktop ? <Pipe /> : null}
              direction={isDesktop ? 'horizontal' : 'vertical'}
            >
              {data.salaryRequestHour ? (
                <span>時給 {formatNumber(data.salaryRequestHour)} 円</span>
              ) : null}
              {data.salaryRequestMonth ? (
                <span>月給 {formatNumber(data.salaryRequestMonth)} 円</span>
              ) : null}
              {data.salaryRequestYear ? (
                <span>年収 {formatNumber(data.salaryRequestYear)} 円</span>
              ) : null}
            </Space>
          )}
      </Definitions.Item>
      <Definitions.Item title="給与下限" fluid>
        {(data.salaryLimitHour ||
          data.salaryLimitMonth ||
          data.salaryLimitYear) && (
            <Space
              split={isDesktop ? <Pipe /> : null}
              direction={isDesktop ? 'horizontal' : 'vertical'}
            >
              {data.salaryLimitHour ? (
                <span>時給 {formatNumber(data.salaryLimitHour)} 円</span>
              ) : null}
              {data.salaryLimitMonth ? (
                <span>月給 {formatNumber(data.salaryLimitMonth)} 円</span>
              ) : null}
              {data.salaryLimitYear ? (
                <span>年収 {formatNumber(data.salaryLimitYear)} 円</span>
              ) : null}
            </Space>
          )}
      </Definitions.Item>
    </Definitions>
  );
}

import { useState } from 'react';
import { Link } from 'react-router-dom';
import { Card, Row, Col, Form, Input, Button, Typography, Divider, Alert } from 'antd';
import Page from '../components/Page';
import SiteName from '../components/SiteName';
import { forgetUsernname } from '../api/auth';
import useInputErrors from '../hooks/input-errors';

const STATUS_INIT = 'STATUS_INIT';
const STATUS_SEND = 'STATUS_SEND';
const STATUS_ERROR = 'STATUS_ERROR';

export default function ForgetUsername() {
  const [loading, setLoading] = useState(false);
  const [pageStatus, setPageStatus] = useState(STATUS_INIT);

  const {
    errorFields, addError, clearErrors, commitErrors, renderErrors
  } = useInputErrors(['email']);

  const validate = (values) => {
    const { email } = values;

    clearErrors();
    let valid = true;

    if (!email) {
      addError('email', 'メールアドレスを入力してください。');
      valid = false;
    }

    if (!valid) commitErrors();

    return valid;
  }

  const submit = async values => {
    if (!validate(values)) return false;

    const { email } = values;

    setLoading(true);

    const response = await forgetUsernname({ email })
      .catch(err => err.response);

    if (response.status === 200) {
      setPageStatus(STATUS_SEND);
    } else {
      setPageStatus(STATUS_ERROR);
    }

      setLoading(false);
  };

  return (
    <Page simple>
      <Row justify="center">
        <Col lg={12} span={24}>
          <Card bordered={false} className="layout-header">
            <Row justify="center">
              <Col><SiteName homeLink={false} /></Col>
            </Row>
          </Card>
          <Card bordered={false}>
            <Typography.Title level={5}>ユーザーIDをお忘れの方</Typography.Title>
            <div className="mt-md">
              <p>入力されたメールアドレスに、ユーザーIDを送信します。</p>
              <p>メールが届かない場合は、メールアドレスに関連するアカウントが登録されていない可能性がございますので、弊社HPより<a href="https://www.ats-jp.com/contact/">お問い合わせ</a>ください。</p>
            </div>
            <Divider />
            {renderErrors()}
            {pageStatus === STATUS_INIT && (
              <Form layout="vertical" onFinish={submit}>
                <Form.Item
                  label="メールアドレス"
                  name="email"
                  validateStatus={errorFields.email ? 'error' : null}
                >
                  <Input />
                </Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  block
                  loading={loading}
                >
                  ユーザーIDを送信する
                </Button>
              </Form>
            )}
            {pageStatus === STATUS_SEND && (
              <Alert
                message="ユーザーIDを送信しました。"
                type="success"
                showIcon
              />
            )}
            {pageStatus === STATUS_ERROR && (
              <p>システムエラーが発生しました。画面を読み込みなおして再試行いただくか、<a href="https://www.ats-jp.com/contact/">こちら</a>からお問い合わせくださいませ。</p>
            )}
            <Divider />
            <Link to="/login" className="ant-btn ant-btn-block">
              ログインページに戻る
            </Link>
          </Card>
        </Col>
      </Row>
    </Page>
  );
}

/**
 * オブジェクトから、指定したキーのみ抽出した新しいオブジェクトを作成する
 * @param {object} obj
 * @param {array} keys
 */
export function pick(obj, keys) {
  const result = {};

  Object.keys(obj).forEach(key => {
    if (keys.includes(key)) {
      result[key] = obj[key];
    }
  });

  return result;
}

/**
 * オブジェクトの配列から、指定されたキーの値のみピックアップした配列を作成する
 * @param {array} arr
 * @param {string} key
 */
export function pluck(arr, key) {
  return arr.filter(a => typeof a[key] !== 'undefined').map(a => a[key]);
}

import { Form, Input, Select } from 'antd';
import Definitions from '../../components/Definitions';
import EditableCard from '../../components/EditableCard';
import { useOptions } from '../../reducers/options';
import { formLayout } from '../../helpers/form';
import { pick, pluck } from '../../helpers/objects';
import useInput from '../../hooks/input';
import useEditable from '../../hooks/editable';
import useValidation from '../../hooks/validation';

const INPUT_NAMES = [
  { name: 'hrbcId', rules: false },
  { name: 'occupations', rules: ['required'] },
  { name: 'skills', rules: false },
  { name: 'jobDetail', rules: false },
];

export default function Job({ data, onSubmit }) {
  const { isEdit, toggleEdit, sending, send } = useEditable();

  if (!data) return null;

  const inputs = pick(data, pluck(INPUT_NAMES, 'name'));

  const submit = value => send(() => onSubmit(value));

  return (
    <EditableCard
      isEdit={isEdit()}
      onToggle={toggleEdit}
      sending={sending}
      title="希望業務内容"
      readBlock={<ReadBlock data={inputs} />}
      editBlock={
        <EditBlock data={inputs} onSubmit={submit} toggleEdit={toggleEdit} />
      }
    />
  );
}

function EditBlock({ data, onSubmit, toggleEdit }) {
  const { error, validate } = useValidation(INPUT_NAMES);
  const { options } = useOptions();
  const { input, patchInput, inputProps } = useInput(data);
  const skillData = options['skills'];
  const occupationData = options['occupations'];

  const handleFinish = () => validate(input, () => onSubmit(input));

  return (
    <Form onFinish={handleFinish} {...formLayout}>
      <Form.Item
        label="職種"
        validateStatus={error.when('occupations', 'error')}
      >
        <Select
          mode="multiple"
          showArrow
          allowClear
          value={input.occupations || []}
          onChange={val => patchInput({ occupations: val })}
          placeholder="選択してください　※複数選択可"
        >
          {occupationData.map(oData => (
            <Select.OptGroup key={oData.id} label={oData.name}>
              {oData.items.map(oItems => (
                <Select.Option key={oItems.id} value={oItems.id}>
                  {oItems.name}
                </Select.Option>
              ))}
            </Select.OptGroup>
          ))}
        </Select>
        {error.render('occupations', '職種')}
      </Form.Item>
      <Form.Item label="スキル">
        <Select
          mode="multiple"
          showArrow
          allowClear
          value={input.skills || []}
          onChange={val => patchInput({ skills: val })}
          placeholder="選択してください　※複数選択可"
        >
          {skillData.map(sData => (
            <Select.OptGroup key={sData.id} label={sData.name}>
              {sData.items.map(sItem => (
                <Select.Option key={sItem.id} value={sItem.id}>
                  {sItem.name}
                </Select.Option>
              ))}
            </Select.OptGroup>
          ))}
        </Select>
      </Form.Item>
      <Form.Item label="業務詳細">
        <Input.Group compact>
          <Input.TextArea
            {...inputProps('jobDetail')}
            autoSize={{ minRows: 2 }}
          />
        </Input.Group>
      </Form.Item>
      <div className="mt-lg">
        <EditableCard.Footer onCancel={toggleEdit} />
      </div>
    </Form>
  );
}

function ReadBlock({ data }) {
  const { options } = useOptions();
  const occupationItems = options.occupations.reduce(
    (prev, item) => [...prev, ...item.items],
    []
  );
  const skillItems = options.skills.reduce(
    (prev, item) => [...prev, ...item.items],
    []
  );

  return (
    <Definitions>
      <Definitions.Item title="職種">
        {occupationItems
          .filter(occupation => data.occupations.includes(occupation.id))
          .map(occupation => occupation.name)
          .join(', ')}
      </Definitions.Item>
      <Definitions.Item title="スキル" fluid>
        {skillItems
          .filter(skill => data.skills.includes(skill.id))
          .map(skill => skill.name)
          .join(', ')}
      </Definitions.Item>
      <Definitions.Item title="業務詳細" fluid>
        {data.jobDetail}
      </Definitions.Item>
    </Definitions>
  );
}

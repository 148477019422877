import { useState } from 'react';
import useInput from '../../../hooks/input';
import { geoCoding } from '../../../api/google';

export function useContactInput(initialValue) {
  const { input, patchInput, makePatch, inputProps } = useInput(initialValue);
  const [addressNotFound, setAddressNotFound] = useState(false);

  const patchZipCode = async e => {
    setAddressNotFound(false);
    const { value } = e.target;

    if (value.length === 7) {
      const zipCode = toHankaku(value);
      const address = await fetchAddress(zipCode);
      if (address) {
        patchInput({ address, zipCode });
        return;
      }
      setAddressNotFound(true);
    }

    patchInput({ zipCode: value });
  };

  return {
    input,
    patchInput,
    patchZipCode,
    addressNotFound,
    makePatch,
    inputProps,
  };
}

function toHankaku(text) {
  return text.replace(/[！-～]/g, function (t) {
    return String.fromCharCode(t.charCodeAt(0) - 0xfee0);
  });
}

async function fetchAddress(postalCode) {
  const response = await geoCoding(postalCode).catch(err => err.response);

  if (response.status !== 200 || response.data.results.length === 0) {
    return null;
  }

  const address = response.data.results[0].address_components;

  return [
    getAddressName(address, 'administrative_area_level_1'),
    getAddressName(address, 'locality'),
    getAddressName(address, 'sublocality'),
  ].join(' ');
}

function getAddressName(components, type) {
  return components
    .filter(({ types }) => types.includes(type))
    .map(({ long_name }) => long_name)
    .reverse()
    .join(' ');
}

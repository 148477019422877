import { Typography } from 'antd';

const { Text } = Typography;

function Definitions({ children, stack = false }) {
  let classNames = 'definitions';

  if (stack) {
    classNames += ' is-stack';
  }

  return <dl className={classNames}>{children}</dl>;
}

Definitions.Item = function({ title, children }) {
  return (
    <div className="definition">
      <dt className="definition-title">
        <Text type="secondary">{title}</Text>
      </dt>
      <dd className="definition-description">{children}</dd>
    </div>
  );
}

export default Definitions;

import { Card, Typography, Spin, Grid, Row, Col, Button } from 'antd';

const { Link } = Typography;

function EditableCard({
  isEdit = false,
  onToggle,
  title,
  readBlock,
  editBlock,
  sending = false,
}) {
  const { lg: isDesktop } = Grid.useBreakpoint();

  const handleLinkClick = e => {
    e.preventDefault();
    onToggle();
  };

  const renderCardExtra = () => (
    <Link href="#" onClick={handleLinkClick}>
      {isEdit ? 'キャンセル' : '編集する'}
    </Link>
  );

  return (
    <Spin spinning={sending}>
      <Card
        className="raised"
        title={title}
        extra={renderCardExtra()}
        size={isDesktop ? 'default' : 'small'}
      >
        {isEdit ? editBlock : readBlock}
      </Card>
    </Spin>
  );
}

EditableCard.Footer = function Footer({ onCancel, loading = false }) {
  const { lg: isDesktop } = Grid.useBreakpoint();

  return (
    <Row
      gutter={isDesktop ? 16 : [12, 12]}
      justify={isDesktop ? 'end' : 'start'}
    >
      <Col span={isDesktop ? null : 24} order={isDesktop ? 1 : 2}>
        <Button
          type="default"
          onClick={onCancel}
          disabled={loading}
          block={!isDesktop}
        >
          キャンセル
        </Button>
      </Col>
      <Col span={isDesktop ? null : 24} order={isDesktop ? 2 : 1}>
        <Button
          type="primary"
          htmlType="submit"
          loading={loading}
          block={!isDesktop}
        >
          確定する
        </Button>
      </Col>
    </Row>
  );
};

export default EditableCard;

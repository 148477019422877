import { useState } from 'react';

const STATE_READ = 'READ';
const STATE_EDIT = 'EDIT';

export default function useEditable() {
  const [state, setState] = useState(STATE_READ);
  const [sending, setSending] = useState(false);

  return {
    isEdit: () => state === STATE_EDIT,
    toggleEdit: () => setState(state === STATE_READ ? STATE_EDIT : STATE_READ),
    sending,
    send: async callback => {
      setSending(true);
      await callback();
      setState(STATE_READ);
      setSending(false);
    }
  };
}

import { withPage } from '../components/Page';
import { Avatar, Space } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import ResponsiveCard from '../components/ResponsiveCard';
import { ReactComponent as PdfIcon } from '../assets/pdf.svg';

function Download() {
  return (
    <ResponsiveCard>
      <h1 className="page-title">
        <Avatar icon={<DownloadOutlined />} />
        <span>資料ダウンロード</span>
      </h1>
      <Space className="fluid" direction="vertical" size="large">
        <FileCard>
          <a href="https://ari-jp.com" target="_blank" rel="noreferrer">
            情報セキュリティー
          </a>
        </FileCard>
        <FileCard>
          <a href="https://ari-jp.com" target="_blank" rel="noreferrer">
            スタッフ心得
          </a>
        </FileCard>
      </Space>
    </ResponsiveCard>
  );
}

function FileCard({ children }) {
  return (
    <ResponsiveCard className="raised">
      <Space size="large" style={{ display: 'flex' }}>
        <PdfIcon width="2em" style={{ display: 'block' }} />
        {children}
      </Space>
    </ResponsiveCard>
  );
}

export default withPage(Download);

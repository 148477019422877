import { Form, Select, Row, Col } from 'antd';
import EditableCard from '../../components/EditableCard';
import { useOptions } from '../../reducers/options';
import { formLayout } from '../../helpers/form';
import { pick } from '../../helpers/objects';
import useInput from '../../hooks/input';
import useEditable from '../../hooks/editable';

const INPUT_NAMES = [
  'hrbcId',
  'priorities1',
  'priorities2',
  'priorities3',
  'priorities4',
];

export default function Priorities({ data, onSubmit }) {
  const { isEdit, toggleEdit, sending, send } = useEditable();

  if (!data) return null;

  const inputs = pick(data, INPUT_NAMES);

  const submit = value => send(() => onSubmit(value));

  return (
    <EditableCard
      isEdit={isEdit()}
      onToggle={toggleEdit}
      sending={sending}
      title="優先順位"
      readBlock={<ReadBlock data={inputs} />}
      editBlock={
        <EditBlock data={inputs} onSubmit={submit} toggleEdit={toggleEdit} />
      }
    />
  );
}

function EditBlock({ data, onSubmit, toggleEdit }) {
  const { loopOptions } = useOptions();
  const { input, patchInput } = useInput(data);

  return (
    <Form onFinish={() => onSubmit(input)} {...formLayout}>
      <Form.Item label="1.">
        <Select
          value={input.priorities1}
          onChange={val => patchInput({ priorities1: val })}
          placeholder="選択してください"
        >
          {loopOptions('priorities1', (val, label) => (
            <Select.Option key={val} value={val}>
              {label}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item label="2.">
        <Select
          value={input.priorities2}
          onChange={val => patchInput({ priorities2: val })}
          placeholder="選択してください"
        >
          {loopOptions('priorities2', (val, label) => (
            <Select.Option key={val} value={val}>
              {label}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item label="3.">
        <Select
          value={input.priorities3}
          onChange={val => patchInput({ priorities3: val })}
          placeholder="選択してください"
        >
          {loopOptions('priorities3', (val, label) => (
            <Select.Option key={val} value={val}>
              {label}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item label="4.">
        <Select
          value={input.priorities4}
          onChange={val => patchInput({ priorities4: val })}
          placeholder="選択してください"
        >
          {loopOptions('priorities4', (val, label) => (
            <Select.Option key={val} value={val}>
              {label}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <div className="mt-lg">
        <EditableCard.Footer onCancel={toggleEdit} />
      </div>
    </Form>
  );
}

function ReadBlock({ data }) {
  const { getOptionLabel } = useOptions();

  const render = (val, rootKey) => (val ? getOptionLabel(rootKey, val) : '-');

  return (
    <Row>
      <Col span={24} lg={6}>
        <div className="mb-sm-mobile">1. {render(data.priorities1, 'priorities1')}</div>
      </Col>
      <Col span={24} lg={6}>
        <div className="mb-sm-mobile">2. {render(data.priorities2, 'priorities2')}</div>
      </Col>
      <Col span={24} lg={6}>
        <div className="mb-sm-mobile">3. {render(data.priorities3, 'priorities3')}</div>
      </Col>
      <Col span={24} lg={6}>
        4. {render(data.priorities4, 'priorities4')}
      </Col>
    </Row>
  );
}

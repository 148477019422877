import { Avatar } from 'antd';
import Icon from '@ant-design/icons';
import { withPage } from '../components/Page';
import ResponsiveCard from '../components/ResponsiveCard';

const Signpost = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    fill="currentColor"
    viewBox="0 0 16 16"
    className="text-primary"
  >
    <path d="M7 7V1.414a1 1 0 0 1 2 0V2h5a1 1 0 0 1 .8.4l.975 1.3a.5.5 0 0 1 0 .6L14.8 5.6a1 1 0 0 1-.8.4H9v10H7v-5H2a1 1 0 0 1-.8-.4L.225 9.3a.5.5 0 0 1 0-.6L1.2 7.4A1 1 0 0 1 2 7h5zm1 3V8H2l-.75 1L2 10h6zm0-5h6l.75-1L14 3H8v2z" />
  </svg>
);

function NotFound() {
  return (
    <ResponsiveCard>
      <h1 className="page-title">
        <Avatar icon={<Icon component={Signpost} />} />
        <span>Not Found</span>
      </h1>
      <div>お探しのページは見つかりませんでした。</div>
    </ResponsiveCard>
  );
}

export default withPage(NotFound);

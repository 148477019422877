import { useState } from 'react';
import { Avatar, Grid, Button, message, Typography, Alert } from 'antd';
import Icon from '@ant-design/icons';
import { withPage } from '../components/Page';
import ResponsiveCard from '../components/ResponsiveCard';
import { withdraw } from '../api/user';

const { Text } = Typography;

const DoorOpen = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    fill="currentColor"
    className="text-primary"
    viewBox="0 0 16 16"
  >
    <path d="M8.5 10c-.276 0-.5-.448-.5-1s.224-1 .5-1 .5.448.5 1-.224 1-.5 1z" />
    <path d="M10.828.122A.5.5 0 0 1 11 .5V1h.5A1.5 1.5 0 0 1 13 2.5V15h1.5a.5.5 0 0 1 0 1h-13a.5.5 0 0 1 0-1H3V1.5a.5.5 0 0 1 .43-.495l7-1a.5.5 0 0 1 .398.117zM11.5 2H11v13h1V2.5a.5.5 0 0 0-.5-.5zM4 1.934V15h6V1.077l-6 .857z" />
  </svg>
);

function Withdrawal({ reportError }) {
  const { lg: isDesktop } = Grid.useBreakpoint();
  const [loading, setLoading] = useState(false);

  const onWithdraw = async () => {
    setLoading(true);
    const response = await withdraw().catch(e => e.response);
    setLoading(false);

    if (response.status === 200) {
      message.open({ content: '退会申請を送信しました。' });
    } else {
      reportError(response.status);
    }
  };

  return (
    <ResponsiveCard>
      <h1 className="page-title">
        <Avatar icon={<Icon component={DoorOpen} />} />
        <span>退会申請</span>
      </h1>
      <p>
        退会をご希望の方は注意事項をご確認の上「退会する」をクリックしてください。
      </p>
      <Alert
        message={
          <>
            <div className="mb-sm">
              <Text strong>【注意事項】</Text>
            </div>
            <div>
              退会申請を行うとWEBサービスはご利用頂けなくなり、全ての登録データが抹消されます。
              <br />
              尚、手続きはシステムの都合上、２～３日お時間をいただいておりますのでご了承ください。
            </div>
          </>
        }
      />
      <div className="mt-lg">
        <Button
          type="dashed"
          loading={loading}
          onClick={onWithdraw}
          block={!isDesktop}
        >
          退会申請を送信する
        </Button>
      </div>
    </ResponsiveCard>
  );
}

export default withPage(Withdrawal);

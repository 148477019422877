import { useState } from 'react';
import { Layout, Drawer, Button, Space } from 'antd';
import { MenuFoldOutlined } from '@ant-design/icons';
import Header from './Header';
import Footer from './Footer';
import SiderContent from './SiderContent';
import ErrorBoundary from './ErrorBoundary';
import SystemError from './SystemError';
import Notification from './Notification';
import useSystemError from '../hooks/system-error';
import useMaintenance from '../hooks/maintenance';
import useNotification from '../hooks/useNotification';
import { useCurrentUser } from '../reducers/user';

function Page({ children, simple = false }) {
  const [sideDrawerOpen, setSideDrawerOpen] = useState(false);
  const user = useCurrentUser();
  const { notification } = useNotification();

  if (simple) return <SimplePage children={children} />;

  return (
    <Layout className="layout">
      <Header
        userName={user.username}
        onSidebarToggle={() => setSideDrawerOpen(!sideDrawerOpen)}
      />
      <Layout>
        <Layout.Sider className="layout-sider">
          <SiderContent />
        </Layout.Sider>
        <Layout.Content className="layout-main">
          <div className="layout-page">
            <Space direction="vertical" style={{ width: '100%' }}>
              {notification && (
                <Notification text={notification.text} url={notification.url} />
              )}
              {children}
            </Space>
          </div>
          <Footer />
        </Layout.Content>
      </Layout>
      <Drawer
        visible={sideDrawerOpen}
        closable={false}
        placement="left"
        bodyStyle={{ padding: 0, paddingBottom: 24 }}
      >
        <div style={{ padding: 18 }}>
          <Button
            type="text"
            icon={<MenuFoldOutlined />}
            onClick={() => setSideDrawerOpen(false)}
          />
        </div>
        <div style={{ padding: 18, marginTop: 12 }}>{user.name}</div>
        <SiderContent />
      </Drawer>
    </Layout>
  );
}

function SimplePage({ children }) {
  return (
    <Layout className="layout">
      <Layout.Content className="layout-main">
        <div className="layout-page">{children}</div>
      </Layout.Content>
    </Layout>
  );
}

export default Page;

export function withPage(Component, pageProps = {}) {
  return props => {
    const { systemError, crash } = useSystemError();
    const { maintenance, enableMaintenance } = useMaintenance();

    const reportError = code => {
      if (code === 503) {
        enableMaintenance();
      } else {
        crash();
      }
    };

    if (maintenance) {
      window.location.href = '/maintenance';
      return null;
    }

    return (
      <Page {...pageProps}>
        {systemError ? (
          <SystemError />
        ) : (
          <ErrorBoundary>
            <Component {...props} reportError={reportError} />
          </ErrorBoundary>
        )}
      </Page>
    );
  };
}

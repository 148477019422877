import { useEffect, useState } from 'react';
import { fetchProfile } from '../../../api/profile';

export default function useFetchProfile({ onError }) {
  const [profile, setProfile] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const task = async () => {
      setLoading(true);

      const response = await fetchProfile().catch(res => res.response);

      if (response.status !== 200) {
        onError(response.status);
        setLoading(false);
      }

      setProfile(response.data);

      setLoading(false);
    };
    task();
  }, [onError]);

  const patchProfile = patch => setProfile({ ...profile, ...patch });

  return { profile, patchProfile, profileLoading: loading };
}

export function fetchFiles(params = {}) {
  const url = '/resume-files';
  return window.axios.get(url, { params });
}

export function uploadFile(file) {
  const formData = new FormData();
  formData.append('file', file);

  return window.axios.post('/resume-files', formData);
}

export function show() {
  return window.axios.get('/resume');
}

export function update(data) {
  return window.axios.put('/resume', data);
}

export function downloadFile(attachmentId) {
  return window.axios.get(`/files-download/${attachmentId}`)
}

import { Form, Input, Radio } from 'antd';
import Definitions from '../../components/Definitions';
import EditableCard from '../../components/EditableCard';
import { useOptions } from '../../reducers/options';
import { pick } from '../../helpers/objects';
import useInput from '../../hooks/input';
import useEditable from '../../hooks/editable';

const othersFormLayout = {
  colon: false,
  labelAlign: 'left',
  layout: 'vertical',
};

const INPUT_NAMES = [
  'hrbcId',
  'consideration',
  'opinionRequest',
  'activityStatus',
];

export default function Skills({ data, onSubmit }) {
  const { isEdit, toggleEdit, sending, send } = useEditable();

  if (!data) return null;

  const inputs = pick(data, INPUT_NAMES);

  const submit = value => send(() => onSubmit(value));

  return (
    <EditableCard
      isEdit={isEdit()}
      onToggle={toggleEdit}
      sending={sending}
      title="その他"
      readBlock={<ReadBlock data={inputs} />}
      editBlock={
        <EditBlock data={inputs} onSubmit={submit} toggleEdit={toggleEdit} />
      }
    />
  );
}

function EditBlock({ data, onSubmit, toggleEdit }) {
  const { loopOptions } = useOptions();
  const { input, inputProps } = useInput(data);

  return (
    <Form onFinish={() => onSubmit(input)} {...othersFormLayout}>
      <Form.Item label="仕事をご紹介する上で、配慮が必要な方はお申し出ください。">
        <Input.Group compact>
          <Input.TextArea
            {...inputProps('consideration')}
            autoSize={{ minRows: 2 }}
          />
        </Input.Group>
      </Form.Item>
      <Form.Item label="弊社に期待したいこと、ご意見・ご要望などご自由にご記入ください。">
        <Input.Group compact>
          <Input.TextArea
            {...inputProps('opinionRequest')}
            autoSize={{ minRows: 2 }}
          />
        </Input.Group>
      </Form.Item>
      <Form.Item label="現在の活動状況">
        <Radio.Group {...inputProps('activityStatus')}>
          {loopOptions('activityStatus', (key, val) => (
            <Radio key={key} value={key}>
              {val}
            </Radio>
          ))}
        </Radio.Group>
      </Form.Item>
      <div className="mt-lg">
        <EditableCard.Footer onCancel={toggleEdit} />
      </div>
    </Form>
  );
}

function ReadBlock({ data }) {
  const { getOptionLabel } = useOptions();

  return (
    <Definitions stack>
      <Definitions.Item title="仕事をご紹介する上で、配慮が必要な方はお申し出ください。">
        {data.consideration}
      </Definitions.Item>
      <Definitions.Item title="弊社に期待したいこと、ご意見・ご要望などご自由にご記入ください。">
        {data.opinionRequest}
      </Definitions.Item>
      <Definitions.Item title="現在の活動状況" fluid>
        {getOptionLabel('activityStatus', data.activityStatus)}
      </Definitions.Item>
    </Definitions>
  );
}

import { useState, useEffect } from 'react';
import { notification as notificationApi } from '../api/notification';

export default function useNotification() {
  const [notification, setNotification] = useState(null);

  useEffect(() => {
    (async () => {
      const response = await notificationApi();

      if (response.status === 200) {
        setNotification(response.data);
      }
      if (response.status === 204) {
        setNotification(null);
      }
    })();
  }, []);

  return { notification };
}

import { Navigate } from 'react-router-dom';
import Login from './pages/Login';
import ForgetUsername from './pages/ForgetUsername';
import ForgetPassword from './pages/ForgetPassword';
import ResetPassword from './pages/ResetPassword';
import Profile from './pages/Profile/';
import Resume from './pages/Resume/';
import Download from './pages/Download';
import NotFound from './pages/NotFound';
import Withdrawal from './pages/Withdrawal';

const routes = [
  {
    path: '/',
    element: <Navigate to="/profile" replace={true} />,
    private: true,
  },
  { path: 'profile', element: <Profile />, private: true },
  { path: 'resume', element: <Resume />, private: true },
  { path: 'download', element: <Download />, private: true },
  { path: 'withdrawal', element: <Withdrawal />, private: true },
  { path: 'login', element: <Login />, private: false },
  { path: 'forget-username', element: <ForgetUsername />, private: false },
  { path: 'forget-password', element: <ForgetPassword />, private: false },
  { path: 'reset-password/:token', element: <ResetPassword />, private: false },
  { path: '*', element: <NotFound />, private: true },
];

export default routes;

import { useState } from 'react';
import { Typography, Alert } from 'antd';
const { Text } = Typography;

export default function useInputErrors(fieldNames) {
  const [errorMessages, setErrorMessages] = useState([]);

  const initialFields = {};
  fieldNames.forEach(field => {
    initialFields[field] = false;
  });

  const [errorFields, setErrorFields] = useState(initialFields);

  let messages = [];
  let fields = Object.assign({}, initialFields);

  const clearErrors = () => {
    setErrorMessages([]);
    setErrorFields(initialFields);
  };

  const addError = (name, message) => {
    if (name) {
      fields = Object.assign({}, fields, { [name]: true });
    }

    messages = [...messages, message];
  };

  const commitErrors = () => {
    setErrorMessages(messages);
    setErrorFields(fields);
  };

  const renderErrors = () => {
    if (errorMessages.length === 0) return null;

    return (
      <div className="mb-lg">
        <Alert
          type="error"
          message={errorMessages.map(m => (
            <div key={m}><Text type="danger">{m}</Text></div>
          ))}
        />
      </div>
    )
  }

  return {
    errorMessages,
    errorFields,
    addError,
    clearErrors,
    commitErrors,
    renderErrors
  };
}

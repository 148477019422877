import { useEffect } from 'react';
import { Card } from 'antd';
import { fetchUser } from '../api/user';
import { withPage } from '../components/Page';
import SiteName from '../components/SiteName';

function Maintenance() {
  useEffect(() => {
    fetchUser()
      .catch(err => err.response)
      .then(response => {
        if (response.status !== 503) {
          window.location.href = '/';
        }
      });
  }, []);

  return (
    <Card bordered={false} title={<SiteName homeLink={false} />}>
      <h1 className="page-title">
        <span>ただいまシステムのメンテナンス中です。</span>
      </h1>
      <p style={{ marginBottom: 0 }}>
        システムアップデートのため現在一時的にサービスを停止しています。
        <br />
        皆様にはご不便をおかけしますが、メンテナンス終了までいましばらくお待ちください。
      </p>
    </Card>
  );
}

export default withPage(Maintenance, { simple: true });

import React from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios';
import App, { MaintenanceMode } from './App';
import store from './store';
import { fetchUser } from './api/user';
import { setUser } from './reducers/user';

window.axios = axios.create({
  withCredentials: true,
  withXSRFToken : true,
  baseURL: process.env.REACT_APP_API_URL,
});

fetchUser()
  .then(response => {
    if (response.data) {
      store.dispatch(setUser(response.data));
    }

    renderApp();
  })
  .catch(renderApp);

function renderApp(error = null) {
  const maintenance = error && error.response && error.response.status === 503;

  ReactDOM.render(
    maintenance ? <MaintenanceMode /> : <App />,
    document.getElementById('root')
  );
}

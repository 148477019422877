import { Space, Form, Input, Radio, Select, DatePicker, Grid } from 'antd';
import moment from 'moment';
import Definitions from '../../components/Definitions';
import Pipe from '../../components/Pipe';
import EditableCard from '../../components/EditableCard';
import { useOptions } from '../../reducers/options';
import { useInputStyle, formLayout } from '../../helpers/form';
import { monthFormat } from '../../helpers/format';
import { pick } from '../../helpers/objects';
import useInput from '../../hooks/input';
import useEditable from '../../hooks/editable';

const INPUT_NAMES = [
  'hrbcId',
  'education',
  'graduateYearMonth',
  'graduation',
  'schoolName',
  'majorName',
  'majorType',
];

export default function Education({ data, onSubmit }) {
  const { isEdit, toggleEdit, sending, send } = useEditable();

  if (!data) return null;

  const inputs = pick(data, INPUT_NAMES);

  const submit = value => send(() => onSubmit(value));

  return (
    <EditableCard
      isEdit={isEdit()}
      onToggle={toggleEdit}
      sending={sending}
      title="学歴"
      readBlock={<ReadBlock data={inputs} />}
      editBlock={
        <EditBlock data={inputs} onSubmit={submit} toggleEdit={toggleEdit} />
      }
    />
  );
}

function EditBlock({ data, onSubmit, toggleEdit }) {
  const { lg: isDesktop } = Grid.useBreakpoint();
  const { styleHalf } = useInputStyle();
  const { loopOptions } = useOptions();
  const { input, patchInput, inputProps } = useInput(data);

  return (
    <Form onFinish={() => onSubmit(input)} {...formLayout}>
      <Form.Item label="最終学歴">
        <Space
          className="fluid"
          direction={isDesktop ? 'horizontal' : 'vertical'}
          size={isDesktop ? 'small' : 'middle'}
        >
          <Select
            value={input.education}
            onChange={val => patchInput({ education: val })}
            style={{
              width: '70px'
            }}
          >
            {loopOptions('education', (val, label) => (
              <Select.Option key={val} value={val}>
                {label}
              </Select.Option>
            ))}
          </Select>
          <DatePicker
            value={
              input.graduateYearMonth
                ? moment(input.graduateYearMonth, monthFormat)
                : null
            }
            picker="month"
            onChange={val =>
              patchInput({
                graduateYearMonth: val ? val.format('YYYY-MM') : '',
              })
            }
            dropdownClassName="hide-footer"
            format={monthFormat}
            className="fluid-mobile"
          />
          <Radio.Group {...inputProps('graduation')}>
            {loopOptions('graduation', (key, val) => (
              <Radio key={key} value={key}>
                {val}
              </Radio>
            ))}
          </Radio.Group>
        </Space>
      </Form.Item>
      <Form.Item label="学校名">
        <Input.Group compact>
          <Input
            {...inputProps('schoolName')}
            placeholder="学校名"
            {...styleHalf}
          />
        </Input.Group>
      </Form.Item>
      <Form.Item label="学部学科名">
        <Input.Group compact>
          <Input
            {...inputProps('majorName')}
            placeholder="学部 学科名"
            {...styleHalf}
          />
        </Input.Group>
      </Form.Item>
      <Form.Item label="学部系統">
        <Select
          value={input.majorType}
          onChange={val => patchInput({ majorType: val })}
          placeholder="選択してください"
        >
          {loopOptions('majorType', (val, label) => (
            <Select.Option key={val} value={val}>
              {label}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <div className="mt-lg">
        <EditableCard.Footer onCancel={toggleEdit} />
      </div>
    </Form>
  );
}

function ReadBlock({ data }) {
  const { getOptionLabel } = useOptions();

  return (
    <Definitions>
      <Definitions.Item title="最終学歴">
        <Space split={<Pipe />}>
          <span>{getOptionLabel('education', data.education)}</span>
          <span>
            {moment(data.graduateYearMonth).format(monthFormat)}{' '}
            {getOptionLabel('graduation', data.graduation)}
          </span>
        </Space>
      </Definitions.Item>
      <Definitions.Item title="学校・学部学科名">
        <Space>
          {data.schoolName}
          {data.majorName}
        </Space>
      </Definitions.Item>
      <Definitions.Item title="学部系統" fluid>
        {getOptionLabel('majorType', data.majorType)}
      </Definitions.Item>
    </Definitions>
  );
}

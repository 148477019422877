import { combineReducers } from "redux";
import { configureStore } from '@reduxjs/toolkit';
import optionsReducer from './reducers/options';
import userReducer from './reducers/user';

const reducer = combineReducers({
  options: optionsReducer,
  user: userReducer,
});

const store = configureStore({ reducer });

export default store;

export async function login(data) {
  await window.axios.get('/sanctum/csrf-cookie');
  return window.axios.post('/login', data);
}

export function logout() {
  return window.axios.post('/logout');
}

export function forgetUsernname(data) {
  return window.axios.post('/forgot-username', data);
}

export function forgetPassword(data) {
  return window.axios.post('/forgot-password', data);
}

export function resetPassword(data) {
  return window.axios.post('/reset-password', data);
}

import { useState, useEffect } from 'react';

export default function useTabState(tab1, tab2) {
  const [activeKey, setActiveKey] = useState('1');

  useEffect(() => {
    // ページロード時にハッシュ値をチェックしてタブを設定
    const hash = window.location.hash;
    if (hash === tab1) {
      setActiveKey('1');
    } else if (hash === tab2) {
      setActiveKey('2');
    } else {
      setActiveKey('1');
    }
  }, [tab1, tab2]);

  const handleTabChange = key => {
    setActiveKey(key);
    // タブ変更時にハッシュ値を設定
    if (key === '1') {
      window.location.hash = tab1;
    } else {
      window.location.hash = tab2;
    }
  };

  return { activeKey, handleTabChange };
}

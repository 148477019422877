import { useState } from 'react';

export default function useSystemError() {
  const [systemError, setSystemError] = useState(false);

  return {
    systemError,
    crash: () => setSystemError(true),
  };
}

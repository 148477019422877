import { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Menu } from 'antd';
import {
  // DownloadOutlined,
  UserOutlined,
  ProfileOutlined,
} from '@ant-design/icons';

const menuItems = [
  { path: '/profile', label: 'プロフィール', icon: <UserOutlined /> },
  { path: '/resume', label: '職務経歴書', icon: <ProfileOutlined /> },
  // { path: '/download', label: '資料ダウンロード', icon: <DownloadOutlined /> },
];

function AppMenu() {
  const [selectedKeys, setSelectedKeys] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    setSelectedKeys([location.pathname]);
  }, [location.pathname]);

  return (
    <Menu mode="inline" selectedKeys={selectedKeys} style={{ borderRight: 0 }}>
      {menuItems.map(menuItem => (
        <Menu.Item
          key={menuItem.path}
          icon={menuItem.icon}
          onClick={() => navigate(menuItem.path)}
        >
          {menuItem.label}
        </Menu.Item>
      ))}
    </Menu>
  );
}

export default AppMenu;

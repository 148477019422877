import moment from 'moment';
import { useState, useEffect, useCallback } from 'react';
import { fetchFiles } from '../../../api/resume';
import { fullDateFormat } from '../../../helpers/format';

export default function useFetchFiles({ onError }) {
  const [files, setFiles] = useState([]);
  const [pagination, setPagination] = useState({ currentPage: 1, total: 0 });
  const [loading, setLoading] = useState(false);

  const pageChange = useCallback(
    async page => {
      const response = await fetchFiles({ page }).catch(res => res.response);

      setLoading(true);

      if (response.status !== 200) {
        onError(response.status);
        setLoading(false);
      }

      const { data, current_page, total } = response.data;

      const newFiles = data.map(file => ({
        key: file.id,
        fileName: file.filename,
        registrationDate: moment(file.created_at).format(fullDateFormat),
        attachmentId: file.external_file_id,
      }))

      setFiles(newFiles);
      setPagination({ currentPage: current_page, total: total });

      setLoading(false);
    },
    [onError]
  );

  useEffect(() => {
    pageChange(1);
  }, [pageChange]);

  return { files, pagination, pageChange, filesLoading: loading };
}

import { createSlice } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import options from '../data/options';

const { reducer } = createSlice({
  name: 'options',
  initialState: options,
});

export function useOptions() {
  const options = useSelector(state => state.options);

  const isOptionEmpty = () => Object.keys(options).length === 0;

  // 選択肢でループ
  // セレクトボックスなどでの利用を想定
  const loopOptions = (rootKey, callback) => {
    if (!options[rootKey]) return null;

    return options[rootKey].map(options => {
      return callback(options.id, options.name);
    });
  };

  // 選択肢が2階層の場合のループ処理
  // 名称で絞り込む
  const loopOptionItems = (rootKey, name, callback) => {
    if (!options[rootKey]) return null;

    const option = options[rootKey].find(o => o.name === name);
    if (!option) return null;

    return option.items.map(options => {
      return callback(options.id, options.name);
    });
  };

  // 選択肢の名称を取得する
  const getOptionLabel = (rootKey, value) => {
    if (!options[rootKey]) return '';

    const list = options[rootKey][0].items // 2階層?
      ? options[rootKey].reduce((prev, item) => [...prev, ...item.items], [])
      : options[rootKey];

    const selected = list.find(op => op.id === value);

    return selected ? selected.name : '';
  };

  // 選択肢の名称を取得する（複数）
  const getOptionLabels = (rootKey, values) => {
    if (!options[rootKey]) return '';

    return options[rootKey]
      .filter(op => values.includes(op.id))
      .map(op => op.name)
      .join(', ');
  };

  // 選択肢のIDを取得する
  const getOptionId = (rootKey, value) => {
    if (!options[rootKey]) return '';

    const list = options[rootKey][0].items // 2階層?
      ? options[rootKey].reduce((prev, item) => [...prev, ...item.items], [])
      : options[rootKey];

    const selected = list.find(op => op.name === value);

    return selected ? selected.id : '';
  };

  return {
    options,
    isOptionEmpty,
    loopOptions,
    loopOptionItems,
    getOptionLabel,
    getOptionLabels,
    getOptionId
  };
}

export default reducer;

import AppMenu from './AppMenu';
import { Divider, Menu } from 'antd';

export default function SiderContent() {
  return (
    <>
      <AppMenu />
      <Divider />
      <Menu mode="inline" style={{ borderRight: 0 }}>
        <Menu.ItemGroup title="ATSの求人サイト">
          <Menu.Item style={{ height: 48 }}>
            <a
              href="https://www.techpass.jp"
              target="_blank"
              rel="noreferrer"
              style={{ display: 'block' }}
            >
              <img
                height={28}
                style={{ paddingLeft: 8 }}
                src="/techpass.svg"
                alt=""
              />
            </a>
          </Menu.Item>
          <Menu.Item style={{ height: 60 }}>
            <a
              href="https://career.techpass.jp"
              target="_blank"
              rel="noreferrer"
              style={{ display: 'block' }}
            >
              <img height={59} src="/careerpass.svg" alt="" />
            </a>
          </Menu.Item>
        </Menu.ItemGroup>
      </Menu>
    </>
  );
}

import { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Card, Row, Col, Form, Input, Button, Typography } from 'antd';
import Page from '../components/Page';
import SiteName from '../components/SiteName';
import { resetPassword } from '../api/auth';
import useInputErrors from '../hooks/input-errors';

const { Title, Text } = Typography;

const STATUS_INIT = 'STATUS_INIT';
const STATUS_SEND = 'STATUS_SEND';
const STATUS_ERROR = 'STATUS_ERROR';

const isValidPassword = val =>
  /[!-~]{10,}/.test(val) && /[a-zA-Z]/.test(val) && /[0-9]/.test(val);

export default function ResetPassword() {
  const [loading, setLoading] = useState(false);
  const [pageStatus, setPageStatus] = useState(STATUS_INIT);
  const { token } = useParams();

  const { errorFields, addError, clearErrors, commitErrors, renderErrors } =
    useInputErrors(['email', 'password', 'password_confirmation']);

  const validate = values => {
    const { email, password, password_confirmation } = values;

    clearErrors();
    let valid = true;

    if (!email) {
      addError('email', 'メールアドレスを入力してください。');
      valid = false;
    } else if (!/^.*@.*$/.test(email)) {
      addError('email', 'メールアドレスの形式で入力してください。');
      valid = false;
    }

    if (!password) {
      addError('password', 'パスワードを入力してください。');
      valid = false;
    }

    if (!isValidPassword(password)) {
      addError('password', 'パスワードを正しく入力してください。');
      valid = false;
    }

    if (!password_confirmation) {
      addError('password_confirm', 'パスワード（確認）を入力してください。');
      valid = false;
    }

    if (valid && password !== password_confirmation) {
      addError(
        'password_confirm',
        'パスワードとパスワード（確認）が異なります。'
      );
      valid = false;
    }

    if (!valid) commitErrors();

    return valid;
  };

  const submit = async values => {
    if (!validate(values)) return false;

    const { email, password, password_confirmation } = values;

    setLoading(true);

    const response = await resetPassword({
      email,
      password,
      password_confirmation,
      token,
    }).catch(err => err.response);

    if (response.status === 200) {
      setPageStatus(STATUS_SEND);
    } else {
      setPageStatus(STATUS_ERROR);
    }

    setLoading(false);
  };

  return (
    <Page simple>
      <Row justify="center">
        <Col lg={12} span={24}>
          <Card bordered={false} className="layout-header">
            <Row justify="center">
              <Col>
                <SiteName homeLink={false} />
              </Col>
            </Row>
          </Card>
          <Card bordered={false}>
            <div className="mb-lg">
              <Title level={5}>パスワード再設定</Title>
            </div>
            {renderErrors()}
            {pageStatus === STATUS_INIT && (
              <Form layout="vertical" onFinish={submit}>
                <Form.Item
                  label="メールアドレス"
                  name="email"
                  validateStatus={errorFields.email ? 'error' : null}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label="新パスワード"
                  validateStatus={errorFields.password ? 'error' : null}
                >
                  <Form.Item name="password" noStyle>
                    <Input.Password />
                  </Form.Item>
                  <div className="mt-sm">
                    <Text type="secondary">
                      半角英字および数字を含む10文字以上
                    </Text>
                  </div>
                </Form.Item>
                <Form.Item
                  label="新パスワード（確認）"
                  name="password_confirmation"
                  validateStatus={
                    errorFields.password_confirmation ? 'error' : null
                  }
                >
                  <Input.Password />
                </Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  block
                  loading={loading}
                >
                  パスワードを変更する
                </Button>
              </Form>
            )}
            {pageStatus === STATUS_SEND && (
              <>
                <p>パスワードを変更しました。</p>
                <Link to="/login" className="ant-btn ant-btn-block">
                  ログインページに戻る
                </Link>
              </>
            )}
            {pageStatus === STATUS_ERROR && (
              <>
                <p>
                  リンクの有効期限が切れています。お手数ですが、再度「パスワードをお忘れの方」ページより操作をやり直してください。
                </p>
                <Link to="/forget-password" className="ant-btn ant-btn-block">
                  パスワードをお忘れの方ページに戻る
                </Link>
              </>
            )}
          </Card>
        </Col>
      </Row>
    </Page>
  );
}

import { useState } from 'react';
import { Typography, Alert } from 'antd';

const { Text, Paragraph } = Typography;

const errorWrapperClassName = 'ant-form-item-has-error';

const notEmpty = val =>
  Array.isArray(val) ? val.length > 0 : val !== '' && val !== null;

export const validations = {
  required: {
    handler: notEmpty,
    message: name => `${name}を入力してください。`,
  },
  requiredUnless: {
    handler: (val, arg) => notEmpty(arg) || notEmpty(val),
    message: name => `${name}を入力してください。`,
  },
  email: {
    handler: val => (notEmpty(val) ? val.indexOf('@') > 0 : true),
    message: name => `${name}を正しい形式で入力してください。`,
  },
};

class ValidationError {
  constructor() {
    this.items = {};
  }

  put(name, rule) {
    this.items[name] = rule;
  }

  isEmpty() {
    return Object.keys(this.items).length === 0;
  }

  hasAny() {
    return Object.keys(this.items).length > 0;
  }

  get(name) {
    if (typeof this.items[name] === 'undefined') {
      return null;
    }

    return this.items[name] || null;
  }

  remove(name) {
    const _remove = n => {
      if (this.items[n]) delete this.items[n];
    };

    if (Array.isArray(name)) {
      name.forEach(n => _remove(n));
    } else {
      _remove(name);
    }
  }

  getMessage(name, label) {
    const rule = this.get(name);
    if (!rule) return '';
    const { message } = validations[rule];
    return message(label);
  }

  getMessages(args) {
    return args
      .map(({ name, label }) => this.getMessage(name, label))
      .filter(err => !!err);
  }

  when(name, val) {
    const error = this.get(name);

    return error ? val : null;
  }

  className(name) {
    return this.when(name, errorWrapperClassName);
  }

  render(name, label) {
    const message = this.getMessage(name, label);

    if (!message) return null;

    return (
      <div className="mt-sm">
        <Text type="danger">{message}</Text>
      </div>
    );
  }

  renderList(list) {
    const items = list.filter(({ name }) => this.get(name));

    if (items.length === 0) return null;

    return (
      <Alert
        type="error"
        message={items.map(({ name, label }) => (
          <Paragraph key={name} type="danger" style={{ margin: `4px 0` }}>
            {this.getMessage(name, label)}
          </Paragraph>
        ))}
        style={{ backgroundColor: 'transparent' }}
      />
    );
  }
}

export default function useValidation(config) {
  const [error, setError] = useState(new ValidationError());

  const validate = (values, onSuccess) => {
    const res = _validate(config, values);

    setError(res);

    if (res.isEmpty()) {
      onSuccess();
    }
  };

  return { validate, error };
}

function _validate(config, values) {
  const result = new ValidationError();

  const validateByRule = (key, value, rule) => {
    if (Array.isArray(rule)) {
      const [ruleName, option] = rule;
      const { handler } = validations[ruleName];

      const arg = (() => {
        if (option.with) return values[option.with];
      })();

      if (!handler(value, arg)) {
        return result.put(key, ruleName);
      }
    }

    if (typeof rule === 'string') {
      const { handler } = validations[rule];

      if (!handler(value)) {
        return result.put(key, rule);
      }
    }
  };

  config.forEach(({ name, rules }) => {
    if (!rules) return false;

    // 配列のバリデーション
    if (name.indexOf('.') > 0) {
      const [key, prop] = name.split('.');

      const value = values[key];
      if (typeof value === 'undefined') return false;

      rules.forEach(rule => {
        value.forEach((item, index) => {
          validateByRule(`${key}.${index}.${prop}`, item[prop], rule);
        });
      });

      return false;
    }

    const value = values[name];
    if (typeof value === 'undefined') return false;

    rules.forEach(rule => validateByRule(name, value, rule));
  });

  return result;
}

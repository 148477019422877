import { useState } from 'react';

export default function useMaintenance() {
  const [maintenance, setMaintenance] = useState(false);

  return {
    maintenance,
    enableMaintenance: () => setMaintenance(true),
  };
}

import { Form, Input, Select } from 'antd';
import Definitions from '../../components/Definitions';
import EditableCard from '../../components/EditableCard';
import { useOptions } from '../../reducers/options';
import { formLayout } from '../../helpers/form';
import { pick } from '../../helpers/objects';
import useInput from '../../hooks/input';
import useEditable from '../../hooks/editable';

const INPUT_NAMES = [
  'hrbcId',
  'qualification',
  'englishReading',
  'englishWriting',
  'englishSpeaking',
  'otherLanguage',
  'pcTechnicalSkill',
];

export default function Education({ data, onSubmit }) {
  const { isEdit, toggleEdit, sending, send } = useEditable();

  if (!data) return null;

  const inputs = pick(data, INPUT_NAMES);

  const submit = value => send(() => onSubmit(value));

  return (
    <EditableCard
      isEdit={isEdit()}
      onToggle={toggleEdit}
      sending={sending}
      title="資格・スキル"
      readBlock={<ReadBlock data={inputs} />}
      editBlock={
        <EditBlock data={inputs} onSubmit={submit} toggleEdit={toggleEdit} />
      }
    />
  );
}

function EditBlock({ data, onSubmit, toggleEdit }) {
  const { loopOptions } = useOptions();
  const { input, patchInput, inputProps } = useInput(data);

  return (
    <Form onFinish={() => onSubmit(input)} {...formLayout}>
      <Form.Item label="資格">
        <Input.Group compact>
          <Input.TextArea
            {...inputProps('qualification')}
            autoSize={{ minRows: 2 }}
            placeholder="例）2017年 TOEIC 900点"
          />
        </Input.Group>
      </Form.Item>
      <Form.Item label="英語 リーディング">
        <Select
          value={input.englishReading}
          onChange={val => patchInput({ englishReading: val })}
          placeholder="選択してください"
        >
          {loopOptions('englishReading', (val, label) => (
            <Select.Option key={val} value={val}>
              {label}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item label="英語 ライティング">
        <Select
          value={input.englishWriting}
          onChange={val => patchInput({ englishWriting: val })}
          placeholder="選択してください"
        >
          {loopOptions('englishWriting', (val, label) => (
            <Select.Option key={val} value={val}>
              {label}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item label="英語 会話">
        <Select
          value={input.englishSpeaking}
          onChange={val => patchInput({ englishSpeaking: val })}
          placeholder="選択してください"
        >
          {loopOptions('englishSpeaking', (val, label) => (
            <Select.Option key={val} value={val}>
              {label}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item label="その他外国語">
        <Input.Group compact>
          <Input.TextArea
            {...inputProps('otherLanguage')}
            autoSize={{ minRows: 2 }}
            placeholder="例）中国語 日常会話レベル"
          />
        </Input.Group>
      </Form.Item>
      <Form.Item label="IT スキル">
        <Input.Group compact>
          <Input.TextArea
            {...inputProps('pcTechnicalSkill')}
            autoSize={{ minRows: 2 }}
            placeholder="例）OS: Windows、UNIX (Solaris)、Linux (RedHat)"
          />
        </Input.Group>
      </Form.Item>
      <div className="mt-lg">
        <EditableCard.Footer onCancel={toggleEdit} />
      </div>
    </Form>
  );
}

function ReadBlock({ data }) {
  const { getOptionLabel } = useOptions();

  return (
    <Definitions>
      <Definitions.Item title="資格">{data.qualification}</Definitions.Item>
      <Definitions.Item title="英語 リーディング">
        {getOptionLabel('englishReading', data.englishReading)}
      </Definitions.Item>
      <Definitions.Item title="英語 ライティング" fluid>
        {getOptionLabel('englishWriting', data.englishWriting)}
      </Definitions.Item>
      <Definitions.Item title="英語 会話" fluid>
        {getOptionLabel('englishSpeaking', data.englishSpeaking)}
      </Definitions.Item>
      <Definitions.Item title="その他外国語" fluid>
        {data.otherLanguage}
      </Definitions.Item>
      <Definitions.Item title="IT スキル" fluid>
        {data.pcTechnicalSkill}
      </Definitions.Item>
    </Definitions>
  );
}

const options = {
  area: [
    {
      id: 'areaGroup01',
      name: '首都圏広域',
      items: [
        {
          id: 13,
          name: '東京都',
          order: 1,
          matchingoodId: '東京都',
        },
        {
          id: 14,
          name: '神奈川県',
          order: 2,
          matchingoodId: '神奈川県',
        },
        {
          id: 12,
          name: '千葉県',
          order: 3,
          matchingoodId: '千葉県',
        },
        {
          id: 11,
          name: '埼玉県',
          order: 4,
          matchingoodId: '埼玉県',
        },
        {
          id: 100,
          name: 'その他関東',
          order: 5,
          matchingoodId: 'その他関東',
        },
      ],
      order: 1,
    },
    {
      id: 'areaGroup02',
      name: '関西広域',
      items: [
        {
          id: 26,
          name: '京都府',
          order: 1,
          matchingoodId: '京都府',
        },
        {
          id: 27,
          name: '大阪府',
          order: 2,
          matchingoodId: '大阪府',
        },
        {
          id: 28,
          name: '兵庫県',
          order: 3,
          matchingoodId: '兵庫県',
        },
        {
          id: 101,
          name: 'その他関西',
          order: 4,
          matchingoodId: 'その他関西',
        },
      ],
      order: 2,
    },
    {
      id: 'areaGroup03',
      name: '東海広域',
      items: [
        {
          id: 23,
          name: '愛知県',
          order: 1,
          matchingoodId: '愛知県',
        },
        {
          id: 21,
          name: '岐阜県',
          order: 2,
          matchingoodId: '岐阜県',
        },
        {
          id: 24,
          name: '三重県',
          order: 3,
          matchingoodId: '三重県',
        },
        {
          id: 102,
          name: 'その他東海広域',
          order: 4,
          matchingoodId: 'その他東海広域',
        },
      ],
      order: 3,
    },
    {
      id: 'areaGroup04',
      name: 'その他',
      items: [
        {
          id: 48,
          name: '海外',
          order: 1,
          matchingoodId: '海外',
        },
        {
          id: 103,
          name: 'その他全国',
          order: 2,
          matchingoodId: 'その他全国',
        },
      ],
      order: 4,
    },
  ],
  gender: [
    {
      id: 52,
      name: '男性',
      order: 1,
      matchingoodId: '男性',
    },
    {
      id: 53,
      name: '女性',
      order: 2,
      matchingoodId: '女性',
    },
  ],
  skills: [
    {
      id: 'skillGroup01',
      name: '▼言語・開発環境',
      items: [
        {
          id: 298,
          name: 'Access VBA',
          order: 1,
          matchingoodId: 'Access VBA',
        },
        {
          id: 299,
          name: 'ActionScript',
          order: 2,
          matchingoodId: 'ActionScript',
        },
        {
          id: 300,
          name: 'Android',
          order: 3,
          matchingoodId: 'Android',
        },
        {
          id: 301,
          name: 'ASP',
          order: 4,
          matchingoodId: 'ASP',
        },
        {
          id: 302,
          name: 'AWS',
          order: 5,
          matchingoodId: 'AWS',
        },
        {
          id: 303,
          name: 'Azure',
          order: 6,
          matchingoodId: 'Azure',
        },
        {
          id: 304,
          name: 'Bluemix',
          order: 7,
          matchingoodId: 'Bluemix',
        },
        {
          id: 305,
          name: 'C言語',
          order: 8,
          matchingoodId: 'C言語',
        },
        {
          id: 306,
          name: 'C#',
          order: 9,
          matchingoodId: 'C#',
        },
        {
          id: 307,
          name: 'C++',
          order: 10,
          matchingoodId: 'C++',
        },
        {
          id: 308,
          name: 'COBOL',
          order: 11,
          matchingoodId: 'COBOL',
        },
        {
          id: 309,
          name: 'CSS',
          order: 12,
          matchingoodId: 'CSS',
        },
        {
          id: 310,
          name: 'Excel VBA',
          order: 13,
          matchingoodId: 'Excel VBA',
        },
        {
          id: 311,
          name: 'Go言語',
          order: 14,
          matchingoodId: 'Go言語',
        },
        {
          id: 312,
          name: 'GCP',
          order: 15,
          matchingoodId: 'GCP',
        },
        {
          id: 313,
          name: 'HTML',
          order: 16,
          matchingoodId: 'HTML',
        },
        {
          id: 314,
          name: 'HTML5',
          order: 17,
          matchingoodId: 'HTML5',
        },
        {
          id: 315,
          name: 'iOS',
          order: 18,
          matchingoodId: 'iOS',
        },
        {
          id: 316,
          name: 'Java',
          order: 19,
          matchingoodId: 'Java',
        },
        {
          id: 317,
          name: 'JavaScript',
          order: 20,
          matchingoodId: 'JavaScript',
        },
        {
          id: 318,
          name: 'Kotlin',
          order: 21,
          matchingoodId: 'Kotlin',
        },
        {
          id: 319,
          name: 'Objective-C',
          order: 22,
          matchingoodId: 'Objective-C',
        },
        {
          id: 320,
          name: 'Office365',
          order: 23,
          matchingoodId: 'Office365',
        },
        {
          id: 321,
          name: 'OracleCloud',
          order: 24,
          matchingoodId: 'OracleCloud',
        },
        {
          id: 322,
          name: 'Perl',
          order: 25,
          matchingoodId: 'Perl',
        },
        {
          id: 323,
          name: 'PHP',
          order: 26,
          matchingoodId: 'PHP',
        },
        {
          id: 324,
          name: 'PL1',
          order: 27,
          matchingoodId: 'PL1',
        },
        {
          id: 325,
          name: 'PL/SQL',
          order: 28,
          matchingoodId: 'PL/SQL',
        },
        {
          id: 326,
          name: 'Python',
          order: 29,
          matchingoodId: 'Python',
        },
        {
          id: 327,
          name: 'R',
          order: 30,
          matchingoodId: 'R',
        },
        {
          id: 328,
          name: 'RPG',
          order: 31,
          matchingoodId: 'RPG',
        },
        {
          id: 329,
          name: 'Ruby',
          order: 32,
          matchingoodId: 'Ruby',
        },
        {
          id: 330,
          name: 'Scala',
          order: 33,
          matchingoodId: 'Scala',
        },
        {
          id: 331,
          name: 'ShellScript',
          order: 34,
          matchingoodId: 'ShellScript',
        },
        {
          id: 332,
          name: 'SQL',
          order: 35,
          matchingoodId: 'SQL',
        },
        {
          id: 333,
          name: 'Swift',
          order: 36,
          matchingoodId: 'Swift',
        },
        {
          id: 334,
          name: 'Unity',
          order: 37,
          matchingoodId: 'Unity',
        },
        {
          id: 335,
          name: 'Vagrant',
          order: 38,
          matchingoodId: 'Vagrant',
        },
        {
          id: 336,
          name: 'VB',
          order: 39,
          matchingoodId: 'VB',
        },
        {
          id: 337,
          name: 'VB.Net',
          order: 40,
          matchingoodId: 'VB.Net',
        },
        {
          id: 338,
          name: 'VC++',
          order: 41,
          matchingoodId: 'VC++',
        },
      ],
      order: 1,
    },
    {
      id: 'skillGroup02',
      name: '▼構成管理',
      items: [
        {
          id: 340,
          name: 'Ansible',
          order: 1,
          matchingoodId: 'Ansible',
        },
        {
          id: 341,
          name: 'Puppet',
          order: 2,
          matchingoodId: 'Puppet',
        },
        {
          id: 342,
          name: 'Terraform',
          order: 3,
          matchingoodId: 'Terraform',
        },
      ],
      order: 2,
    },
    {
      id: 'skillGroup03',
      name: '▼CI/CD',
      items: [
        {
          id: 344,
          name: 'Git',
          order: 1,
          matchingoodId: 'Git',
        },
        {
          id: 345,
          name: 'Jenkins',
          order: 2,
          matchingoodId: 'Jenkins',
        },
        {
          id: 346,
          name: 'Maven',
          order: 3,
          matchingoodId: 'Maven',
        },
        {
          id: 347,
          name: 'Selenium',
          order: 4,
          matchingoodId: 'Selenium',
        },
        {
          id: 348,
          name: 'Serverspec',
          order: 5,
          matchingoodId: 'Serverspec',
        },
        {
          id: 349,
          name: 'Subversion',
          order: 6,
          matchingoodId: 'Subversion',
        },
      ],
      order: 3,
    },
    {
      id: 'skillGroup04',
      name: '▼OS',
      items: [
        {
          id: 351,
          name: 'AIX',
          order: 1,
          matchingoodId: 'AIX',
        },
        {
          id: 352,
          name: 'CentOS',
          order: 2,
          matchingoodId: 'CentOS',
        },
        {
          id: 353,
          name: 'HP-UX',
          order: 3,
          matchingoodId: 'HP-UX',
        },
        {
          id: 354,
          name: 'Linux',
          order: 4,
          matchingoodId: 'Linux',
        },
        {
          id: 355,
          name: 'MacOS',
          order: 5,
          matchingoodId: 'MacOS',
        },
        {
          id: 356,
          name: 'OS/390',
          order: 6,
          matchingoodId: 'OS/390',
        },
        {
          id: 357,
          name: 'Redhat Enterprise Linux',
          order: 7,
          matchingoodId: 'Redhat Enterprise Linux',
        },
        {
          id: 358,
          name: 'Solaris',
          order: 8,
          matchingoodId: 'Solaris',
        },
        {
          id: 359,
          name: 'Ubuntu',
          order: 9,
          matchingoodId: 'Ubuntu',
        },
        {
          id: 360,
          name: 'UNIX',
          order: 10,
          matchingoodId: 'UNIX',
        },
        {
          id: 361,
          name: 'Windows Server',
          order: 11,
          matchingoodId: 'Windows Server',
        },
        {
          id: 362,
          name: 'z/OS',
          order: 12,
          matchingoodId: 'z/OS',
        },
      ],
      order: 4,
    },
    {
      id: 'skillGroup05',
      name: '▼サーバソフト・GW',
      items: [
        {
          id: 364,
          name: 'Active Directory',
          order: 1,
          matchingoodId: 'Active Directory',
        },
        {
          id: 365,
          name: 'Exchange Server',
          order: 2,
          matchingoodId: 'Exchange Server',
        },
        {
          id: 366,
          name: 'Notes/Domino',
          order: 3,
          matchingoodId: 'Notes/Domino',
        },
        {
          id: 367,
          name: 'Postfix',
          order: 4,
          matchingoodId: 'Postfix',
        },
        {
          id: 368,
          name: 'RADIUS',
          order: 5,
          matchingoodId: 'RADIUS',
        },
        {
          id: 369,
          name: 'Sendmail',
          order: 6,
          matchingoodId: 'Sendmail',
        },
        {
          id: 370,
          name: 'SharePoint',
          order: 7,
          matchingoodId: 'SharePoint',
        },
      ],
      order: 5,
    },
    {
      id: 'skillGroup06',
      name: '▼仮想化',
      items: [
        {
          id: 372,
          name: 'CloudStack',
          order: 1,
          matchingoodId: 'CloudStack',
        },
        {
          id: 373,
          name: 'Docker',
          order: 2,
          matchingoodId: 'Docker',
        },
        {
          id: 374,
          name: 'Hyper-V',
          order: 3,
          matchingoodId: 'Hyper-V',
        },
        {
          id: 375,
          name: 'KVM',
          order: 4,
          matchingoodId: 'KVM',
        },
        {
          id: 376,
          name: 'Kubernetes',
          order: 5,
          matchingoodId: 'Kubernetes',
        },
        {
          id: 377,
          name: 'Mesos',
          order: 6,
          matchingoodId: 'Mesos',
        },
        {
          id: 378,
          name: 'OpenStack',
          order: 7,
          matchingoodId: 'OpenStack',
        },
        {
          id: 379,
          name: 'SDN',
          order: 8,
          matchingoodId: 'SDN',
        },
        {
          id: 380,
          name: 'vCloud Director',
          order: 9,
          matchingoodId: 'vCloud Director',
        },
        {
          id: 381,
          name: 'VMware',
          order: 10,
          matchingoodId: 'VMware',
        },
        {
          id: 382,
          name: 'Xen',
          order: 11,
          matchingoodId: 'Xen',
        },
      ],
      order: 6,
    },
    {
      id: 'skillGroup07',
      name: '▼ストレージ',
      items: [
        {
          id: 384,
          name: 'EMC',
          order: 1,
          matchingoodId: 'EMC',
        },
        {
          id: 385,
          name: 'HP製',
          order: 2,
          matchingoodId: 'HP製',
        },
        {
          id: 386,
          name: 'IBM製',
          order: 3,
          matchingoodId: 'IBM製',
        },
        {
          id: 387,
          name: 'NEC製',
          order: 4,
          matchingoodId: 'NEC製',
        },
        {
          id: 388,
          name: 'NetApp',
          order: 5,
          matchingoodId: 'NetApp',
        },
        {
          id: 389,
          name: '富士通製',
          order: 6,
          matchingoodId: '富士通製',
        },
        {
          id: 390,
          name: '日立製',
          order: 7,
          matchingoodId: '日立製',
        },
      ],
      order: 7,
    },
    {
      id: 'skillGroup08',
      name: '▼運用監視',
      items: [
        {
          id: 392,
          name: 'Fluentd',
          order: 1,
          matchingoodId: 'Fluentd',
        },
        {
          id: 393,
          name: 'Hinemos',
          order: 2,
          matchingoodId: 'Hinemos',
        },
        {
          id: 394,
          name: 'JP1',
          order: 3,
          matchingoodId: 'JP1',
        },
        {
          id: 395,
          name: 'Logstash',
          order: 4,
          matchingoodId: 'Logstash',
        },
        {
          id: 396,
          name: 'Nagios',
          order: 5,
          matchingoodId: 'Nagios',
        },
        {
          id: 397,
          name: 'OpenView',
          order: 6,
          matchingoodId: 'OpenView',
        },
        {
          id: 398,
          name: 'Systemwalker',
          order: 7,
          matchingoodId: 'Systemwalker',
        },
        {
          id: 399,
          name: 'Tivoli',
          order: 8,
          matchingoodId: 'Tivoli',
        },
        {
          id: 400,
          name: 'Zabbix',
          order: 9,
          matchingoodId: 'Zabbix',
        },
      ],
      order: 8,
    },
    {
      id: 'skillGroup09',
      name: '▼ネットワーク',
      items: [
        {
          id: 402,
          name: 'AlaxalA',
          order: 1,
          matchingoodId: 'AlaxalA',
        },
        {
          id: 403,
          name: 'Allied Telesis',
          order: 2,
          matchingoodId: 'Allied Telesis',
        },
        {
          id: 404,
          name: 'A10',
          order: 3,
          matchingoodId: 'A10',
        },
        {
          id: 405,
          name: 'Brocade',
          order: 4,
          matchingoodId: 'Brocade',
        },
        {
          id: 406,
          name: 'Cisco',
          order: 5,
          matchingoodId: 'Cisco',
        },
        {
          id: 407,
          name: 'F5',
          order: 6,
          matchingoodId: 'F5',
        },
        {
          id: 408,
          name: 'Juniper',
          order: 7,
          matchingoodId: 'Juniper',
        },
        {
          id: 409,
          name: 'Palo Alto',
          order: 8,
          matchingoodId: 'Palo Alto',
        },
      ],
      order: 9,
    },
    {
      id: 'skillGroup10',
      name: '▼データベース',
      items: [
        {
          id: 411,
          name: 'Cassandra',
          order: 1,
          matchingoodId: 'Cassandra',
        },
        {
          id: 412,
          name: 'DB2',
          order: 2,
          matchingoodId: 'DB2',
        },
        {
          id: 413,
          name: 'ElasticSearch',
          order: 3,
          matchingoodId: 'ElasticSearch',
        },
        {
          id: 414,
          name: 'Hbase(Hadoop)',
          order: 4,
          matchingoodId: 'Hbase(Hadoop)',
        },
        {
          id: 415,
          name: 'MariaDB',
          order: 5,
          matchingoodId: 'MariaDB',
        },
        {
          id: 416,
          name: 'MongoDB',
          order: 6,
          matchingoodId: 'MongoDB',
        },
        {
          id: 417,
          name: 'MySQL',
          order: 7,
          matchingoodId: 'MySQL',
        },
        {
          id: 418,
          name: 'Oracle',
          order: 8,
          matchingoodId: 'Oracle',
        },
        {
          id: 419,
          name: 'PostgreSQL',
          order: 9,
          matchingoodId: 'PostgreSQL',
        },
        {
          id: 420,
          name: 'Solr',
          order: 10,
          matchingoodId: 'Solr',
        },
        {
          id: 421,
          name: 'Spark',
          order: 11,
          matchingoodId: 'Spark',
        },
        {
          id: 422,
          name: 'Splunk',
          order: 12,
          matchingoodId: 'Splunk',
        },
        {
          id: 423,
          name: 'SQL Server',
          order: 13,
          matchingoodId: 'SQL Server',
        },
      ],
      order: 10,
    },
    {
      id: 'skillGroup11',
      name: '▼セキュリティ',
      items: [
        {
          id: 425,
          name: 'シマンテック',
          order: 1,
          matchingoodId: 'シマンテック',
        },
        {
          id: 426,
          name: 'トレンドマイクロ',
          order: 2,
          matchingoodId: 'トレンドマイクロ',
        },
        {
          id: 427,
          name: 'マカフィー',
          order: 3,
          matchingoodId: 'マカフィー',
        },
      ],
      order: 11,
    },
    {
      id: 'skillGroup12',
      name: '▼デザインツール',
      items: [
        {
          id: 429,
          name: 'Adobe XD',
          order: 1,
          matchingoodId: 'Adobe XD',
        },
        {
          id: 430,
          name: 'After Effects',
          order: 2,
          matchingoodId: 'After Effects',
        },
        {
          id: 431,
          name: 'Director',
          order: 3,
          matchingoodId: 'Director',
        },
        {
          id: 432,
          name: 'Dreamweaver',
          order: 4,
          matchingoodId: 'Dreamweaver',
        },
        {
          id: 433,
          name: 'Flash',
          order: 5,
          matchingoodId: 'Flash',
        },
        {
          id: 434,
          name: 'Illustrator',
          order: 6,
          matchingoodId: 'Illustrator',
        },
        {
          id: 435,
          name: 'Maya',
          order: 7,
          matchingoodId: 'Maya',
        },
        {
          id: 436,
          name: 'Photoshop',
          order: 8,
          matchingoodId: 'Photoshop',
        },
        {
          id: 437,
          name: 'Premiere',
          order: 9,
          matchingoodId: 'Premiere',
        },
        {
          id: 438,
          name: 'SAI',
          order: 10,
          matchingoodId: 'SAI',
        },
        {
          id: 439,
          name: 'Softimage',
          order: 11,
          matchingoodId: 'Softimage',
        },
        {
          id: 440,
          name: '3ds Max',
          order: 12,
          matchingoodId: '3ds Max',
        },
      ],
      order: 12,
    },
    {
      id: 'skillGroup13',
      name: '▼MS Office',
      items: [
        {
          id: 442,
          name: 'Access',
          order: 1,
          matchingoodId: 'Access',
        },
        {
          id: 443,
          name: 'Excel',
          order: 2,
          matchingoodId: 'Excel',
        },
        {
          id: 444,
          name: 'Power Point',
          order: 3,
          matchingoodId: 'Power Point',
        },
        {
          id: 445,
          name: 'Word',
          order: 4,
          matchingoodId: 'Word',
        },
      ],
      order: 13,
    },
    {
      id: 'skillGroup14',
      name: '▼RPA関連',
      items: [
        {
          id: 447,
          name: 'BizRobo',
          order: 1,
          matchingoodId: 'BizRobo',
        },
        {
          id: 448,
          name: 'UiPath',
          order: 2,
          matchingoodId: 'UiPath',
        },
        {
          id: 449,
          name: 'WinActor',
          order: 3,
          matchingoodId: 'WinActor',
        },
      ],
      order: 14,
    },
    {
      id: 'skillGroup15',
      name: '▼ゲーム開発関連',
      items: [
        {
          id: 451,
          name: 'Confluence',
          order: 1,
          matchingoodId: 'Confluence',
        },
        {
          id: 452,
          name: 'JIRA',
          order: 2,
          matchingoodId: 'JIRA',
        },
        {
          id: 453,
          name: 'Slack',
          order: 3,
          matchingoodId: 'Slack',
        },
        {
          id: 454,
          name: 'Spine',
          order: 4,
          matchingoodId: 'Spine',
        },
      ],
      order: 15,
    },
    {
      id: 'skillGroup16',
      name: '▼その他',
      items: [
        {
          id: 456,
          name: 'CAD',
          order: 1,
          matchingoodId: 'CAD',
        },
      ],
      order: 16,
    },
  ],
  weekday: [
    {
      id: 12,
      name: '月',
      order: 1,
      matchingoodId: '月',
    },
    {
      id: 13,
      name: '火',
      order: 2,
      matchingoodId: '火',
    },
    {
      id: 14,
      name: '水',
      order: 3,
      matchingoodId: '水',
    },
    {
      id: 15,
      name: '木',
      order: 4,
      matchingoodId: '木',
    },
    {
      id: 16,
      name: '金',
      order: 5,
      matchingoodId: '金',
    },
    {
      id: 17,
      name: '土',
      order: 6,
      matchingoodId: '土',
    },
    {
      id: 18,
      name: '日',
      order: 7,
      matchingoodId: '日',
    },
    {
      id: 915,
      name: '祝',
      order: 8,
      matchingoodId: '祝',
    },
  ],
  welfare: [
    {
      id: 459,
      name: '社会保険完備',
      order: 1,
      matchingoodId: '社会保険完備',
    },
    {
      id: 460,
      name: '退職金制度',
      order: 2,
      matchingoodId: '退職金制度',
    },
    {
      id: 461,
      name: '保養施設',
      order: 3,
      matchingoodId: '保養施設',
    },
    {
      id: 462,
      name: '社宅・寮',
      order: 4,
      matchingoodId: '社宅・寮',
    },
    {
      id: 463,
      name: '家賃補助',
      order: 5,
      matchingoodId: '家賃補助',
    },
    {
      id: 464,
      name: '住宅取得補助',
      order: 6,
      matchingoodId: '住宅取得補助',
    },
    {
      id: 465,
      name: '企業年金',
      order: 7,
      matchingoodId: '企業年金',
    },
    {
      id: 466,
      name: '従業員持株会',
      order: 8,
      matchingoodId: '従業員持株会',
    },
    {
      id: 467,
      name: 'その他',
      order: 9,
      matchingoodId: 'その他',
    },
  ],
  features: [
    {
      id: 120,
      name: 'ミドル(40代～)活躍中',
      order: 1,
      matchingoodId: 'ミドル(40代～)活躍中',
    },
    {
      id: 121,
      name: 'シニア(50代～)活躍中',
      order: 2,
      matchingoodId: 'シニア(50代～)活躍中',
    },
    {
      id: 122,
      name: '若手活躍中',
      order: 3,
      matchingoodId: '若手活躍中',
    },
    {
      id: 123,
      name: '英語を活かす',
      order: 4,
      matchingoodId: '英語を活かす',
    },
    {
      id: 124,
      name: '外国語を活かす',
      order: 5,
      matchingoodId: '外国語を活かす',
    },
    {
      id: 125,
      name: '韓国語を活かす',
      order: 6,
      matchingoodId: '韓国語を活かす',
    },
    {
      id: 126,
      name: '大手企業',
      order: 7,
      matchingoodId: '大手企業',
    },
    {
      id: 127,
      name: '高収入',
      order: 8,
      matchingoodId: '高収入',
    },
    {
      id: 128,
      name: '交通費別途支給',
      order: 9,
      matchingoodId: '交通費別途支給',
    },
    {
      id: 129,
      name: '未経験OK',
      order: 10,
      matchingoodId: '未経験OK',
    },
    {
      id: 130,
      name: '外国人歓迎',
      order: 11,
      matchingoodId: '外国人歓迎',
    },
    {
      id: 131,
      name: '駅近',
      order: 12,
      matchingoodId: '駅近',
    },
    {
      id: 132,
      name: '車通勤OK',
      order: 13,
      matchingoodId: '車通勤OK',
    },
    {
      id: 133,
      name: '残業なし',
      order: 14,
      matchingoodId: '残業なし',
    },
    {
      id: 134,
      name: '時短勤務',
      order: 15,
      matchingoodId: '時短勤務',
    },
    {
      id: 135,
      name: '平日休み',
      order: 16,
      matchingoodId: '平日休み',
    },
    {
      id: 136,
      name: '土日祝休み',
      order: 17,
      matchingoodId: '土日祝休み',
    },
    {
      id: 137,
      name: 'シフト勤務',
      order: 18,
      matchingoodId: 'シフト勤務',
    },
    {
      id: 138,
      name: '24Hシフト勤務',
      order: 19,
      matchingoodId: '24Hシフト勤務',
    },
    {
      id: 139,
      name: '朝遅めの始業',
      order: 20,
      matchingoodId: '朝遅めの始業',
    },
    {
      id: 140,
      name: '服装自由',
      order: 21,
      matchingoodId: '服装自由',
    },
    {
      id: 141,
      name: '社員食堂有り',
      order: 22,
      matchingoodId: '社員食堂有り',
    },
    {
      id: 142,
      name: '禁煙・分煙',
      order: 23,
      matchingoodId: '禁煙・分煙',
    },
    {
      id: 143,
      name: 'リモート勤務可能',
      order: 24,
      matchingoodId: 'リモート勤務可能',
    },
  ],
  industry: [
    {
      id: 16,
      name: 'IT/通信/SI',
      order: 1,
      matchingoodId: 'IT/通信/SI',
    },
    {
      id: 17,
      name: 'Webサービス/ゲーム',
      order: 2,
      matchingoodId: 'Webサービス/ゲーム',
    },
    {
      id: 18,
      name: 'メーカー',
      order: 3,
      matchingoodId: 'メーカー',
    },
    {
      id: 19,
      name: '商社',
      order: 4,
      matchingoodId: '商社',
    },
    {
      id: 20,
      name: '金融/保険',
      order: 5,
      matchingoodId: '金融/保険',
    },
    {
      id: 21,
      name: '流通/小売',
      order: 6,
      matchingoodId: '流通/小売',
    },
    {
      id: 22,
      name: '物流',
      order: 7,
      matchingoodId: '物流',
    },
    {
      id: 23,
      name: '医療/福祉/介護',
      order: 8,
      matchingoodId: '医療/福祉/介護',
    },
    {
      id: 24,
      name: 'サービス/レジャー',
      order: 9,
      matchingoodId: 'サービス/レジャー',
    },
    {
      id: 25,
      name: '人材/アウトソーシング',
      order: 10,
      matchingoodId: '人材/アウトソーシング',
    },
    {
      id: 26,
      name: 'コンサルティング',
      order: 11,
      matchingoodId: 'コンサルティング',
    },
    {
      id: 27,
      name: 'マスコミ/広告/デザイン',
      order: 12,
      matchingoodId: 'マスコミ/広告/デザイン',
    },
    {
      id: 28,
      name: '官公庁/公共サービス',
      order: 13,
      matchingoodId: '官公庁/公共サービス',
    },
    {
      id: 29,
      name: '不動産/建設/設備',
      order: 14,
      matchingoodId: '不動産/建設/設備',
    },
    {
      id: 30,
      name: '教育(学校/塾)',
      order: 15,
      matchingoodId: '教育(学校/塾)',
    },
    {
      id: 31,
      name: 'その他',
      order: 16,
      matchingoodId: 'その他',
    },
  ],
  education: [
    {
      id: 111,
      name: '大学院',
      order: 1,
      matchingoodId: '大学院',
    },
    {
      id: 112,
      name: '大学',
      order: 2,
      matchingoodId: '大学',
    },
    {
      id: 113,
      name: '短大',
      order: 3,
      matchingoodId: '短大',
    },
    {
      id: 114,
      name: '高専',
      order: 4,
      matchingoodId: '高専',
    },
    {
      id: 115,
      name: '専門',
      order: 5,
      matchingoodId: '専門',
    },
    {
      id: 116,
      name: '高校',
      order: 6,
      matchingoodId: '高校',
    },
    {
      id: 117,
      name: '中学',
      order: 7,
      matchingoodId: '中学',
    },
    {
      id: 118,
      name: 'その他',
      order: 8,
      matchingoodId: 'その他',
    },
  ],
  majorType: [
    {
      id: 47,
      name: '商･経営･経済･政治学系',
      order: 1,
      matchingoodId: '商･経営･経済･政治学系',
    },
    {
      id: 46,
      name: '法学系',
      order: 2,
      matchingoodId: '法学系',
    },
    {
      id: 45,
      name: '教育学系',
      order: 3,
      matchingoodId: '教育学系',
    },
    {
      id: 43,
      name: '理系その他',
      order: 4,
      matchingoodId: '理系その他',
    },
    {
      id: 44,
      name: '文･外国語･国際関係学系',
      order: 5,
      matchingoodId: '文･外国語･国際関係学系',
    },
    {
      id: 42,
      name: '家政：栄養･食物系以外',
      order: 6,
      matchingoodId: '家政：栄養･食物系以外',
    },
    {
      id: 41,
      name: '家政：栄養･食物系',
      order: 7,
      matchingoodId: '家政：栄養･食物系',
    },
    {
      id: 40,
      name: '農学･水産学系',
      order: 8,
      matchingoodId: '農学･水産学系',
    },
    {
      id: 39,
      name: '理工系：その他',
      order: 9,
      matchingoodId: '理工系：その他',
    },
    {
      id: 38,
      name: '理工系：数学･物理･情報',
      order: 10,
      matchingoodId: '理工系：数学･物理･情報',
    },
    {
      id: 37,
      name: '理工系：生物･化学･理学･バイオ',
      order: 11,
      matchingoodId: '理工系：生物･化学･理学･バイオ',
    },
    {
      id: 31,
      name: '医学系',
      order: 12,
      matchingoodId: '医学系',
    },
    {
      id: 32,
      name: '歯学系',
      order: 13,
      matchingoodId: '歯学系',
    },
    {
      id: 33,
      name: '獣医学系',
      order: 14,
      matchingoodId: '獣医学系',
    },
    {
      id: 34,
      name: '薬学系',
      order: 15,
      matchingoodId: '薬学系',
    },
    {
      id: 35,
      name: '看護･保健･衛生･福祉系',
      order: 16,
      matchingoodId: '看護･保健･衛生･福祉系',
    },
    {
      id: 36,
      name: '理工系：電気･電子･機械',
      order: 17,
      matchingoodId: '理工系：電気･電子･機械',
    },
    {
      id: 48,
      name: '文系その他',
      order: 18,
      matchingoodId: '文系その他',
    },
  ],
  shiftWork: [
    {
      id: 42,
      name: '不可',
      order: 1,
      matchingoodId: '不可',
    },
    {
      id: 43,
      name: '可（日勤） ',
      order: 2,
      matchingoodId: '可（日勤） ',
    },
    {
      id: 44,
      name: '可（24h/365日）',
      order: 3,
      matchingoodId: '可（24h/365日）',
    },
    {
      id: 45,
      name: '可（繁忙期のみ）',
      order: 4,
      matchingoodId: '可（繁忙期のみ）',
    },
    {
      id: 46,
      name: 'その他',
      order: 5,
      matchingoodId: 'その他',
    },
  ],
  graduation: [
    {
      id: 73,
      name: '在学',
      order: 1,
      matchingoodId: '在学',
    },
    {
      id: 74,
      name: '卒業',
      order: 2,
      matchingoodId: '卒業',
    },
    {
      id: 75,
      name: '中退',
      order: 3,
      matchingoodId: '中退',
    },
  ],
  holidayWork: [
    {
      id: 47,
      name: '不可',
      order: 1,
      matchingoodId: '不可',
    },
    {
      id: 48,
      name: '土曜日のみ可',
      order: 2,
      matchingoodId: '土曜日のみ可',
    },
    {
      id: 49,
      name: '土日とも可',
      order: 3,
      matchingoodId: '土日とも可',
    },
    {
      id: 50,
      name: '繁忙期のみ可',
      order: 4,
      matchingoodId: '繁忙期のみ可',
    },
    {
      id: 51,
      name: 'その他',
      order: 5,
      matchingoodId: 'その他',
    },
  ],
  occupations: [
    {
      id: 'occupationGroup01',
      name: 'システム開発系',
      items: [
        {
          id: 610,
          name: 'プロジェクトマネージャー/PMO',
          order: 1,
          matchingoodId: 'プロジェクトマネージャー/PMO',
        },
        {
          id: 611,
          name: 'SE/PG(業務/基幹システム系)',
          order: 2,
          matchingoodId: 'SE/PG(業務/基幹システム系)',
        },
        {
          id: 612,
          name: 'SE/PG(Web/モバイル系)',
          order: 3,
          matchingoodId: 'SE/PG(Web/モバイル系)',
        },
        {
          id: 613,
          name: 'SE/PG(汎用系)',
          order: 4,
          matchingoodId: 'SE/PG(汎用系)',
        },
        {
          id: 614,
          name: 'SE/PG(制御/組み込み系)',
          order: 5,
          matchingoodId: 'SE/PG(制御/組み込み系)',
        },
        {
          id: 615,
          name: 'RPA',
          order: 6,
          matchingoodId: 'RPA',
        },
        {
          id: 616,
          name: 'ツール作成(VBA/SQL等)',
          order: 7,
          matchingoodId: 'ツール作成(VBA/SQL等)',
        },
        {
          id: 617,
          name: 'データ解析/AI開発エンジニア',
          order: 8,
          matchingoodId: 'データ解析/AI開発エンジニア',
        },
        {
          id: 618,
          name: 'データベースエンジニア',
          order: 9,
          matchingoodId: 'データベースエンジニア',
        },
        {
          id: 619,
          name: 'システム運用/保守',
          order: 10,
          matchingoodId: 'システム運用/保守',
        },
        {
          id: 620,
          name: '評価/テスト',
          order: 11,
          matchingoodId: '評価/テスト',
        },
        {
          id: 621,
          name: 'その他システム開発系',
          order: 12,
          matchingoodId: 'その他システム開発系',
        },
      ],
      order: 1,
    },
    {
      id: 'occupationGroup02',
      name: 'インフラ/クラウド系',
      items: [
        {
          id: 623,
          name: 'サーバーエンジニア',
          order: 1,
          matchingoodId: 'サーバーエンジニア',
        },
        {
          id: 624,
          name: 'ネットワークエンジニア',
          order: 2,
          matchingoodId: 'ネットワークエンジニア',
        },
        {
          id: 625,
          name: 'クラウドエンジニア',
          order: 3,
          matchingoodId: 'クラウドエンジニア',
        },
        {
          id: 626,
          name: 'セキュリティエンジニア',
          order: 4,
          matchingoodId: 'セキュリティエンジニア',
        },
        {
          id: 627,
          name: 'インフラ運用/保守',
          order: 5,
          matchingoodId: 'インフラ運用/保守',
        },
        {
          id: 628,
          name: 'その他インフラ/クラウド系',
          order: 6,
          matchingoodId: 'その他インフラ/クラウド系',
        },
      ],
      order: 2,
    },
    {
      id: 'occupationGroup03',
      name: '社内SE/ヘルプデスク/その他',
      items: [
        {
          id: 630,
          name: '社内SE',
          order: 1,
          matchingoodId: '社内SE',
        },
        {
          id: 631,
          name: 'ヘルプデスク/サポート',
          order: 2,
          matchingoodId: 'ヘルプデスク/サポート',
        },
        {
          id: 632,
          name: 'その他社内SE/ヘルプデスク系',
          order: 3,
          matchingoodId: 'その他社内SE/ヘルプデスク系',
        },
      ],
      order: 3,
    },
    {
      id: 'occupationGroup04',
      name: 'Web制作系',
      items: [
        {
          id: 634,
          name: 'Webプロデューサー/ディレクター',
          order: 1,
          matchingoodId: 'Webプロデューサー/ディレクター',
        },
        {
          id: 635,
          name: 'Webデザイナー',
          order: 2,
          matchingoodId: 'Webデザイナー',
        },
        {
          id: 636,
          name: 'マークアップエンジニア/コーダー',
          order: 3,
          matchingoodId: 'マークアップエンジニア/コーダー',
        },
        {
          id: 637,
          name: 'Webサイト運用/コンテンツ企画',
          order: 4,
          matchingoodId: 'Webサイト運用/コンテンツ企画',
        },
        {
          id: 638,
          name: 'その他Web制作系',
          order: 5,
          matchingoodId: 'その他Web制作系',
        },
      ],
      order: 4,
    },
    {
      id: 'occupationGroup05',
      name: 'クリエイティブ系',
      items: [
        {
          id: 640,
          name: '編集/校正/ライター/テクニカルライター',
          order: 1,
          matchingoodId: '編集/校正/ライター/テクニカルライター',
        },
        {
          id: 641,
          name: '映像/音響',
          order: 2,
          matchingoodId: '映像/音響',
        },
        {
          id: 642,
          name: 'DTPオペレーター',
          order: 3,
          matchingoodId: 'DTPオペレーター',
        },
        {
          id: 643,
          name: 'グラフィックデザイナー/その他デザイナー',
          order: 4,
          matchingoodId: 'グラフィックデザイナー/その他デザイナー',
        },
        {
          id: 644,
          name: 'その他クリエイティブ系',
          order: 5,
          matchingoodId: 'その他クリエイティブ系',
        },
      ],
      order: 5,
    },
    {
      id: 'occupationGroup06',
      name: 'IT・Webマーケター/コンサルタント系',
      items: [
        {
          id: 646,
          name: 'ITコンサルタント',
          order: 1,
          matchingoodId: 'ITコンサルタント',
        },
        {
          id: 647,
          name: 'Webマーケター',
          order: 2,
          matchingoodId: 'Webマーケター',
        },
        {
          id: 648,
          name: 'その他IT・Webマーケター/コンサルタント系',
          order: 3,
          matchingoodId: 'その他IT・Webマーケター/コンサルタント系',
        },
      ],
      order: 6,
    },
    {
      id: 'occupationGroup07',
      name: 'ゲーム制作系',
      items: [
        {
          id: 650,
          name: 'ゲームプロデューサー/ディレクター',
          order: 1,
          matchingoodId: 'ゲームプロデューサー/ディレクター',
        },
        {
          id: 651,
          name: 'ゲームプランナー',
          order: 2,
          matchingoodId: 'ゲームプランナー',
        },
        {
          id: 652,
          name: 'ゲームプログラマ',
          order: 3,
          matchingoodId: 'ゲームプログラマ',
        },
        {
          id: 653,
          name: 'グラフィックデザイナー(3D/2D)',
          order: 4,
          matchingoodId: 'グラフィックデザイナー(3D/2D)',
        },
        {
          id: 654,
          name: 'ローカライズ/テクニカルディレクター',
          order: 5,
          matchingoodId: 'ローカライズ/テクニカルディレクター',
        },
        {
          id: 655,
          name: 'その他ゲーム制作系',
          order: 6,
          matchingoodId: 'その他ゲーム制作系',
        },
      ],
      order: 7,
    },
    {
      id: 'occupationGroup08',
      name: 'エンジニア(電気/電子/半導体)系',
      items: [
        {
          id: 657,
          name: '研究/開発(電気/電子/半導体)',
          order: 1,
          matchingoodId: '研究/開発(電気/電子/半導体)',
        },
        {
          id: 658,
          name: '回路設計/実装設計(電気/電子/半導体)',
          order: 2,
          matchingoodId: '回路設計/実装設計(電気/電子/半導体)',
        },
        {
          id: 659,
          name: '生産技術/プロセス開発(電気/電子/半導体)',
          order: 3,
          matchingoodId: '生産技術/プロセス開発(電気/電子/半導体)',
        },
        {
          id: 660,
          name: '生産管理/品質管理(電気/電子/半導体)',
          order: 4,
          matchingoodId: '生産管理/品質管理(電気/電子/半導体)',
        },
        {
          id: 661,
          name: 'サービスエンジニア/セールスエンジニア(電気/電子/半導体)',
          order: 5,
          matchingoodId:
            'サービスエンジニア/セールスエンジニア(電気/電子/半導体)',
        },
        {
          id: 662,
          name: 'その他技術系(電気/電子/半導体)',
          order: 6,
          matchingoodId: 'その他技術系(電気/電子/半導体)',
        },
      ],
      order: 8,
    },
    {
      id: 'occupationGroup09',
      name: 'エンジニア(機械/メカトロ)系',
      items: [
        {
          id: 664,
          name: '研究/開発(機械/メカトロ)',
          order: 1,
          matchingoodId: '研究/開発(機械/メカトロ)',
        },
        {
          id: 665,
          name: '機械設計(機械/メカトロ)',
          order: 2,
          matchingoodId: '機械設計(機械/メカトロ)',
        },
        {
          id: 666,
          name: 'メカトロ制御設計(機械/メカトロ)',
          order: 3,
          matchingoodId: 'メカトロ制御設計(機械/メカトロ)',
        },
        {
          id: 667,
          name: '生産技術/プロセス開発(機械/メカトロ)',
          order: 4,
          matchingoodId: '生産技術/プロセス開発(機械/メカトロ)',
        },
        {
          id: 668,
          name: '生産管理/品質管理(機械/メカトロ)',
          order: 5,
          matchingoodId: '生産管理/品質管理(機械/メカトロ)',
        },
        {
          id: 669,
          name: 'サービスエンジニア/セールスエンジニア(機械/メカトロ)',
          order: 6,
          matchingoodId: 'サービスエンジニア/セールスエンジニア(機械/メカトロ)',
        },
        {
          id: 670,
          name: 'その他技術系(機械/メカトロ)',
          order: 7,
          matchingoodId: 'その他技術系(機械/メカトロ)',
        },
      ],
      order: 9,
    },
    {
      id: 'occupationGroup10',
      name: 'エンジニア(化学/素材)系',
      items: [
        {
          id: 672,
          name: '研究/開発(化学/素材)',
          order: 1,
          matchingoodId: '研究/開発(化学/素材)',
        },
        {
          id: 673,
          name: '生産技術/プロセス開発(化学/素材)',
          order: 2,
          matchingoodId: '生産技術/プロセス開発(化学/素材)',
        },
        {
          id: 674,
          name: '生産管理/品質管理(化学/素材)',
          order: 3,
          matchingoodId: '生産管理/品質管理(化学/素材)',
        },
        {
          id: 675,
          name: 'サービスエンジニア/セールスエンジニア(化学/素材)',
          order: 4,
          matchingoodId: 'サービスエンジニア/セールスエンジニア(化学/素材)',
        },
        {
          id: 676,
          name: 'その他技術系(化学/素材)',
          order: 5,
          matchingoodId: 'その他技術系(化学/素材)',
        },
      ],
      order: 10,
    },
    {
      id: 'occupationGroup11',
      name: 'オフィスワーク/事務系',
      items: [
        {
          id: 678,
          name: '一般事務/OA事務',
          order: 1,
          matchingoodId: '一般事務/OA事務',
        },
        {
          id: 679,
          name: '営業事務',
          order: 2,
          matchingoodId: '営業事務',
        },
        {
          id: 680,
          name: '金融事務(保険/銀行)',
          order: 3,
          matchingoodId: '金融事務(保険/銀行)',
        },
        {
          id: 681,
          name: '貿易事務',
          order: 4,
          matchingoodId: '貿易事務',
        },
        {
          id: 682,
          name: '英文事務',
          order: 5,
          matchingoodId: '英文事務',
        },
        {
          id: 683,
          name: 'データ入力',
          order: 6,
          matchingoodId: 'データ入力',
        },
        {
          id: 684,
          name: '秘書/受付',
          order: 7,
          matchingoodId: '秘書/受付',
        },
        {
          id: 685,
          name: '通訳/翻訳',
          order: 8,
          matchingoodId: '通訳/翻訳',
        },
        {
          id: 686,
          name: 'その他オフィスワーク/事務系',
          order: 9,
          matchingoodId: 'その他オフィスワーク/事務系',
        },
      ],
      order: 11,
    },
    {
      id: 'occupationGroup12',
      name: '管理系',
      items: [
        {
          id: 688,
          name: '総務',
          order: 1,
          matchingoodId: '総務',
        },
        {
          id: 689,
          name: '人事(採用/労務/研修)',
          order: 2,
          matchingoodId: '人事(採用/労務/研修)',
        },
        {
          id: 690,
          name: '経理/財務',
          order: 3,
          matchingoodId: '経理/財務',
        },
        {
          id: 691,
          name: '法務',
          order: 4,
          matchingoodId: '法務',
        },
        {
          id: 692,
          name: '宣伝/プロモーション',
          order: 5,
          matchingoodId: '宣伝/プロモーション',
        },
        {
          id: 693,
          name: '広報/マーケティング',
          order: 6,
          matchingoodId: '広報/マーケティング',
        },
        {
          id: 694,
          name: '購買/調達/物流',
          order: 7,
          matchingoodId: '購買/調達/物流',
        },
        {
          id: 695,
          name: '経営企画/事業企画',
          order: 8,
          matchingoodId: '経営企画/事業企画',
        },
        {
          id: 696,
          name: '特許/知的財産',
          order: 9,
          matchingoodId: '特許/知的財産',
        },
        {
          id: 697,
          name: 'その他管理系',
          order: 10,
          matchingoodId: 'その他管理系',
        },
      ],
      order: 12,
    },
    {
      id: 'occupationGroup13',
      name: '営業系',
      items: [
        {
          id: 699,
          name: '営業職',
          order: 1,
          matchingoodId: '営業職',
        },
        {
          id: 700,
          name: '海外営業/貿易営業',
          order: 2,
          matchingoodId: '海外営業/貿易営業',
        },
        {
          id: 701,
          name: '営業支援',
          order: 3,
          matchingoodId: '営業支援',
        },
        {
          id: 702,
          name: 'MR',
          order: 4,
          matchingoodId: 'MR',
        },
        {
          id: 703,
          name: 'その他営業系',
          order: 5,
          matchingoodId: 'その他営業系',
        },
      ],
      order: 13,
    },
    {
      id: 'occupationGroup14',
      name: 'サポート系',
      items: [
        {
          id: 705,
          name: 'カスタマーサポート',
          order: 1,
          matchingoodId: 'カスタマーサポート',
        },
        {
          id: 706,
          name: 'SV/コールセンター管理',
          order: 2,
          matchingoodId: 'SV/コールセンター管理',
        },
        {
          id: 707,
          name: 'テレフォンオペレーター/テレマーケティング',
          order: 3,
          matchingoodId: 'テレフォンオペレーター/テレマーケティング',
        },
        {
          id: 708,
          name: 'その他サポート系',
          order: 4,
          matchingoodId: 'その他サポート系',
        },
      ],
      order: 14,
    },
    {
      id: 'occupationGroup15',
      name: '販売/サービス系',
      items: [
        {
          id: 710,
          name: '店長/店舗スタッフ',
          order: 1,
          matchingoodId: '店長/店舗スタッフ',
        },
        {
          id: 711,
          name: '店舗開発',
          order: 2,
          matchingoodId: '店舗開発',
        },
        {
          id: 712,
          name: 'バイヤー/スーパーバイザー/MD',
          order: 3,
          matchingoodId: 'バイヤー/スーパーバイザー/MD',
        },
        {
          id: 713,
          name: 'その他販売/サービス系',
          order: 4,
          matchingoodId: 'その他販売/サービス系',
        },
      ],
      order: 15,
    },
    {
      id: 'occupationGroup16',
      name: 'コンサルタント系',
      items: [
        {
          id: 715,
          name: '経営/戦略コンサルタント',
          order: 1,
          matchingoodId: '経営/戦略コンサルタント',
        },
        {
          id: 716,
          name: '財務/会計コンサルタント',
          order: 2,
          matchingoodId: '財務/会計コンサルタント',
        },
        {
          id: 717,
          name: '組織/人事コンサルタント',
          order: 3,
          matchingoodId: '組織/人事コンサルタント',
        },
        {
          id: 718,
          name: 'その他コンサルタント系',
          order: 4,
          matchingoodId: 'その他コンサルタント系',
        },
      ],
      order: 16,
    },
    {
      id: 'occupationGroup17',
      name: '建築/土木系',
      items: [
        {
          id: 720,
          name: '設計/開発(建築/土木/不動産)',
          order: 1,
          matchingoodId: '設計/開発(建築/土木/不動産)',
        },
        {
          id: 721,
          name: '施工管理/設備工事(建築/土木/不動産)',
          order: 2,
          matchingoodId: '施工管理/設備工事(建築/土木/不動産)',
        },
        {
          id: 722,
          name: 'その他建築/土木系',
          order: 3,
          matchingoodId: 'その他建築/土木系',
        },
      ],
      order: 17,
    },
    {
      id: 'occupationGroup18',
      name: '各種オペレーター/倉庫/設備系',
      items: [
        {
          id: 724,
          name: '製造オペレーター',
          order: 1,
          matchingoodId: '製造オペレーター',
        },
        {
          id: 725,
          name: 'CADオペレーター',
          order: 2,
          matchingoodId: 'CADオペレーター',
        },
        {
          id: 726,
          name: '倉庫管理/フォークリフト',
          order: 3,
          matchingoodId: '倉庫管理/フォークリフト',
        },
        {
          id: 727,
          name: '設備保全/保守/施設管理',
          order: 4,
          matchingoodId: '設備保全/保守/施設管理',
        },
        {
          id: 728,
          name: 'その他各種オペレーター/倉庫/設備系',
          order: 5,
          matchingoodId: 'その他各種オペレーター/倉庫/設備系',
        },
      ],
      order: 18,
    },
    {
      id: 'occupationGroup19',
      name: '金融系',
      items: [
        {
          id: 730,
          name: '運用/資金管理',
          order: 1,
          matchingoodId: '運用/資金管理',
        },
        {
          id: 731,
          name: '投資理論/アクチュアリー/商品開発',
          order: 2,
          matchingoodId: '投資理論/アクチュアリー/商品開発',
        },
        {
          id: 732,
          name: 'アナリスト/エコノミスト/ストラテジスト',
          order: 3,
          matchingoodId: 'アナリスト/エコノミスト/ストラテジスト',
        },
        {
          id: 733,
          name: '投資銀行系業務',
          order: 4,
          matchingoodId: '投資銀行系業務',
        },
        {
          id: 734,
          name: 'バック/ミドル',
          order: 5,
          matchingoodId: 'バック/ミドル',
        },
        {
          id: 735,
          name: 'その他金融系',
          order: 6,
          matchingoodId: 'その他金融系',
        },
      ],
      order: 19,
    },
    {
      id: 'occupationGroup20',
      name: '経営者',
      items: [
        {
          id: 737,
          name: '経営者/COO/経営幹部',
          order: 1,
          matchingoodId: '経営者/COO/経営幹部',
        },
        {
          id: 738,
          name: 'CFO',
          order: 2,
          matchingoodId: 'CFO',
        },
        {
          id: 739,
          name: 'CTO',
          order: 3,
          matchingoodId: 'CTO',
        },
      ],
      order: 20,
    },
  ],
  priorities1: [
    {
      id: 57,
      name: '給与・報酬',
      order: 1,
      matchingoodId: '給与・報酬',
    },
    {
      id: 58,
      name: '仕事内容',
      order: 2,
      matchingoodId: '仕事内容',
    },
    {
      id: 59,
      name: '就業環境',
      order: 3,
      matchingoodId: '就業環境',
    },
    {
      id: 60,
      name: '企業規模',
      order: 4,
      matchingoodId: '企業規模',
    },
  ],
  priorities2: [
    {
      id: 61,
      name: '給与・報酬',
      order: 1,
      matchingoodId: '給与・報酬',
    },
    {
      id: 62,
      name: '仕事内容',
      order: 2,
      matchingoodId: '仕事内容',
    },
    {
      id: 63,
      name: '就業環境',
      order: 3,
      matchingoodId: '就業環境',
    },
    {
      id: 64,
      name: '企業規模',
      order: 4,
      matchingoodId: '企業規模',
    },
  ],
  priorities3: [
    {
      id: 65,
      name: '給与・報酬',
      order: 1,
      matchingoodId: '給与・報酬',
    },
    {
      id: 66,
      name: '仕事内容',
      order: 2,
      matchingoodId: '仕事内容',
    },
    {
      id: 67,
      name: '就業環境',
      order: 3,
      matchingoodId: '就業環境',
    },
    {
      id: 68,
      name: '企業規模',
      order: 4,
      matchingoodId: '企業規模',
    },
  ],
  priorities4: [
    {
      id: 69,
      name: '給与・報酬',
      order: 1,
      matchingoodId: '給与・報酬',
    },
    {
      id: 70,
      name: '仕事内容',
      order: 2,
      matchingoodId: '仕事内容',
    },
    {
      id: 71,
      name: '就業環境',
      order: 3,
      matchingoodId: '就業環境',
    },
    {
      id: 72,
      name: '企業規模',
      order: 4,
      matchingoodId: '企業規模',
    },
  ],
  baseLocation: [
    {
      id: 'areaGroup01',
      name: '東京',
      order: 1,
      matchingoodId: '東京',
    },
    {
      id: 'areaGroup02',
      name: '大阪',
      order: 2,
      matchingoodId: '大阪',
    },
    {
      id: 'areaGroup03',
      name: '名古屋',
      order: 3,
      matchingoodId: '名古屋',
    },
  ],
  projectRoles: [
    {
      id: 11,
      name: 'メンバー',
      type: 'TECH',
    },
    {
      id: 12,
      name: 'プロジェクトリーダー',
      type: 'TECH',
    },
    {
      id: 13,
      name: 'プロジェクトマネージャー',
      type: 'TECH',
    },
    {
      id: 21,
      name: 'メンバー',
      type: 'OFFICE',
    },
    {
      id: 22,
      name: 'リーダー',
      type: 'OFFICE',
    },
    {
      id: 23,
      name: 'マネージャー',
      type: 'OFFICE',
    },
  ],
  servicePeriod: [
    {
      id: 41,
      name: '期限なし',
      order: 1,
      matchingoodId: '期限なし',
    },
    {
      id: 42,
      name: '長期(3ヶ月以上)',
      order: 2,
      matchingoodId: '長期(3ヶ月以上)',
    },
    {
      id: 43,
      name: '短期(3ヶ月未満)',
      order: 3,
      matchingoodId: '短期(3ヶ月未満)',
    },
    {
      id: 44,
      name: '単発(10日以内)',
      order: 4,
      matchingoodId: '単発(10日以内)',
    },
    {
      id: 45,
      name: '1日',
      order: 5,
      matchingoodId: '1日',
    },
    {
      id: 46,
      name: 'その他',
      order: 6,
      matchingoodId: 'その他',
    },
  ],
  activityStatus: [
    {
      id: 201,
      name: '活動中',
      order: 1,
      matchingoodId: '活動中',
    },
    {
      id: 202,
      name: 'これから活動予定',
      order: 2,
      matchingoodId: 'これから活動予定',
    },
  ],
  employmentType: [
    {
      id: 948,
      name: '派遣社員',
      order: 1,
      matchingoodId: '派遣社員',
    },
    {
      id: 949,
      name: '紹介予定派遣',
      order: 2,
      matchingoodId: '紹介予定派遣',
    },
    {
      id: 950,
      name: 'フリーランス',
      order: 3,
      matchingoodId: 'フリーランス',
    },
    {
      id: 951,
      name: '正社員',
      order: 4,
      matchingoodId: '正社員',
    },
    {
      id: 952,
      name: '契約社員',
      order: 5,
      matchingoodId: '契約社員',
    },
    {
      id: 1150,
      name: 'その他',
      order: 6,
      matchingoodId: 'その他',
    },
  ],
  englishReading: [
    {
      id: 181,
      name: 'ビギナーレベル…翻訳ソフトなどを使って英文メールが理解できる',
      order: 1,
      matchingoodId:
        'ビギナーレベル…翻訳ソフトなどを使って英文メールが理解できる',
    },
    {
      id: 223,
      name: 'ベーシックレベル…自力で英文メールが理解できる',
      order: 2,
      matchingoodId: 'ベーシックレベル…自力で英文メールが理解できる',
    },
    {
      id: 225,
      name: 'ビジネスレベル…契約・交渉文書、マニュアルが理解できる',
      order: 3,
      matchingoodId: 'ビジネスレベル…契約・交渉文書、マニュアルが理解できる',
    },
    {
      id: 228,
      name: 'ネイティブレベル…母国語レベル',
      order: 4,
      matchingoodId: 'ネイティブレベル…母国語レベル',
    },
  ],
  englishWriting: [
    {
      id: 182,
      name: 'ビギナーレベル…翻訳ソフトなどを使って英文メールが作成できる',
      order: 1,
      matchingoodId:
        'ビギナーレベル…翻訳ソフトなどを使って英文メールが作成できる',
    },
    {
      id: 224,
      name: 'ベーシックレベル…自力で英文メールが作成できる',
      order: 2,
      matchingoodId: 'ベーシックレベル…自力で英文メールが作成できる',
    },
    {
      id: 226,
      name: 'ビジネスレベル…契約・交渉文書、マニュアルが作成できる',
      order: 3,
      matchingoodId: 'ビジネスレベル…契約・交渉文書、マニュアルが作成できる',
    },
    {
      id: 229,
      name: 'ネイティブレベル…母国語レベル',
      order: 4,
      matchingoodId: 'ネイティブレベル…母国語レベル',
    },
  ],
  transportation: [
    {
      id: 11,
      name: '徒歩',
      order: 1,
      matchingoodId: '徒歩',
    },
    {
      id: 12,
      name: 'バス',
      order: 2,
      matchingoodId: 'バス',
    },
    {
      id: 13,
      name: '自転車',
      order: 3,
      matchingoodId: '自転車',
    },
    {
      id: 14,
      name: 'バイク',
      order: 4,
      matchingoodId: 'バイク',
    },
    {
      id: 15,
      name: '車',
      order: 5,
      matchingoodId: '車',
    },
    {
      id: 160,
      name: 'その他',
      order: 6,
      matchingoodId: 'その他',
    },
  ],
  englishSpeaking: [
    {
      id: 180,
      name: 'ビギナーレベル…片言で会話ができる',
      order: 1,
      matchingoodId: 'ビギナーレベル…片言で会話ができる',
    },
    {
      id: 222,
      name: 'ベーシックレベル…日常会話ができる',
      order: 2,
      matchingoodId: 'ベーシックレベル…日常会話ができる',
    },
    {
      id: 227,
      name: 'ビジネスレベル…ビジネスで会話ができる',
      order: 3,
      matchingoodId: 'ビジネスレベル…ビジネスで会話ができる',
    },
    {
      id: 230,
      name: 'ネイティブレベル…母国語レベル',
      order: 4,
      matchingoodId: 'ネイティブレベル…母国語レベル',
    },
  ],
  registerChannel: [
    {
      id: 32,
      name: 'エン派遣',
      order: 1,
      matchingoodId: 'エン派遣',
    },
    {
      id: 33,
      name: 'はたらこねっと',
      order: 2,
      matchingoodId: 'はたらこねっと',
    },
    {
      id: 34,
      name: 'リクナビ派遣',
      order: 3,
      matchingoodId: 'リクナビ派遣',
    },
    {
      id: 35,
      name: 'リクナビNEXT',
      order: 4,
      matchingoodId: 'リクナビNEXT',
    },
    {
      id: 36,
      name: 'テクパス',
      order: 5,
      matchingoodId: 'テクパス',
    },
    {
      id: 37,
      name: 'テクパス（非会員応募）',
      order: 6,
      matchingoodId: 'テクパス（非会員応募）',
    },
    {
      id: 38,
      name: 'キャリパス',
      order: 7,
      matchingoodId: 'キャリパス',
    },
    {
      id: 39,
      name: 'dodamaps',
      order: 8,
      matchingoodId: 'dodamaps',
    },
    {
      id: 40,
      name: '営業提案',
      order: 9,
      matchingoodId: '営業提案',
    },
    {
      id: 41,
      name: 'その他',
      order: 10,
      matchingoodId: 'その他',
    },
    {
      id: 107,
      name: 'en派遣',
      order: 11,
      matchingoodId: 'en派遣',
    },
    {
      id: 109,
      name: 'テクパス・テクパスオフィス',
      order: 12,
      matchingoodId: 'テクパス・テクパスオフィス',
    },
    {
      id: 194,
      name: 'リクルートダイレクトスカウト',
      order: 14,
      matchingoodId: 'リクルートダイレクトスカウト',
    },
    {
      id: 195,
      name: 'dodaX',
      order: 15,
      matchingoodId: 'dodaX',
    },
    {
      id: 221,
      name: 'enバイト',
      order: 16,
      matchingoodId: 'enバイト',
    },
    {
      id: 250,
      name: 'バイトル',
      order: 17,
      matchingoodId: 'バイトル',
    },
    {
      id: 251,
      name: 'マッハバイト',
      order: 18,
      matchingoodId: 'マッハバイト',
    },
  ],
  residentialForm: [
    {
      id: 1,
      name: '家族同居',
      order: 1,
      matchingoodId: '家族同居',
    },
    {
      id: 2,
      name: '一人住まい',
      order: 2,
      matchingoodId: '一人住まい',
    },
    {
      id: 3,
      name: '寮・社宅',
      order: 3,
      matchingoodId: '寮・社宅',
    },
    {
      id: 4,
      name: '単身赴任',
      order: 4,
      matchingoodId: '単身赴任',
    },
    {
      id: 5,
      name: 'その他',
      order: 5,
      matchingoodId: 'その他',
    },
  ],
  projectProcesses: [
    {
      id: 1,
      name: 'システム企画',
    },
    {
      id: 2,
      name: '要件定義',
    },
    {
      id: 3,
      name: '外部設計',
    },
    {
      id: 4,
      name: '内部設計',
    },
    {
      id: 5,
      name: '製造',
    },
    {
      id: 6,
      name: '単体テスト',
    },
    {
      id: 7,
      name: '結合テスト',
    },
    {
      id: 8,
      name: 'システムテスト',
    },
    {
      id: 9,
      name: '運用テスト',
    },
    {
      id: 10,
      name: 'システム移行',
    },
    {
      id: 11,
      name: '運用・保守',
    },
  ],
  currentEmployment: [
    {
      id: 11332,
      name: '離職中',
      order: 1,
      matchingoodId: '離職中',
    },
    {
      id: 11333,
      name: '派遣社員',
      order: 2,
      matchingoodId: '派遣社員',
    },
    {
      id: 11334,
      name: '紹介予定派遣',
      order: 3,
      matchingoodId: '紹介予定派遣',
    },
    {
      id: 11337,
      name: 'フリーランス',
      order: 4,
      matchingoodId: 'フリーランス',
    },
    {
      id: 11336,
      name: '正社員',
      order: 5,
      matchingoodId: '正社員',
    },
    {
      id: 11335,
      name: '契約社員',
      order: 6,
      matchingoodId: '契約社員',
    },
  ],
  currentEmploymentType: [
    {
      id: 1,
      name: '正社員',
      order: 1,
      matchingoodId: '正社員',
    },
    {
      id: 2,
      name: '派遣社員',
      order: 2,
      matchingoodId: '派遣社員',
    },
    {
      id: 3,
      name: '契約社員',
      order: 3,
      matchingoodId: '契約社員',
    },
    {
      id: 9,
      name: '紹介予定派遣',
      order: 8,
      matchingoodId: '紹介予定派遣',
    },
    {
      id: 19,
      name: 'フリーランス',
      order: 9,
      matchingoodId: 'フリーランス',
    },
  ],
  nearestTransportation1: [
    {
      id: 6,
      name: '電車',
      order: 1,
      matchingoodId: '電車',
    },
    {
      id: 7,
      name: '徒歩',
      order: 2,
      matchingoodId: '徒歩',
    },
    {
      id: 8,
      name: 'バス',
      order: 3,
      matchingoodId: 'バス',
    },
    {
      id: 9,
      name: '自転車',
      order: 4,
      matchingoodId: '自転車',
    },
    {
      id: 10,
      name: 'バイク',
      order: 5,
      matchingoodId: 'バイク',
    },
    {
      id: 106,
      name: '車',
      order: 6,
      matchingoodId: '車',
    },
    {
      id: 159,
      name: 'その他',
      order: 7,
      matchingoodId: 'その他',
    },
  ],
  nearestTransportation2: [
    {
      id: 11,
      name: '徒歩',
      order: 1,
      matchingoodId: '徒歩',
    },
    {
      id: 12,
      name: 'バス',
      order: 2,
      matchingoodId: 'バス',
    },
    {
      id: 13,
      name: '自転車',
      order: 3,
      matchingoodId: '自転車',
    },
    {
      id: 14,
      name: 'バイク',
      order: 4,
      matchingoodId: 'バイク',
    },
    {
      id: 15,
      name: '車',
      order: 5,
      matchingoodId: '車',
    },
    {
      id: 160,
      name: 'その他',
      order: 6,
      matchingoodId: 'その他',
    },
  ],
};

export default options;

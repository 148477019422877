import { Layout } from 'antd';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useCurrentUser, setUser } from '../reducers/user';
import Pipe from './Pipe';
import { logout as logoutAPI } from '../api/auth';

export default function Footer() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useCurrentUser();

  const logout = async () => {
    await logoutAPI();
    dispatch(setUser(null));
  };

  const withdrawal = () => navigate('/withdrawal');

  return (
    <Layout.Footer className="layout-footer">
      <span>&copy; ATS, Inc.</span>
      {user ? (
        <>
          <Pipe />
          <span className="text-clickable" onClick={logout}>ログアウト</span>
          <Pipe />
          <span className="text-clickable" onClick={withdrawal}>退会申請</span>
        </>
      ) : null}
    </Layout.Footer>
  );
}

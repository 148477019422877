import { Card, Space, Typography } from 'antd';
import Pipe from '../../components/Pipe';
import Definitions from '../../components/Definitions';
import { useOptions } from '../../reducers/options';
import nl2br from '../../helpers/nl2br';

const { Text } = Typography;

export default function ProjectHistoryCard({ history, type }) {
  const { getOptionLabel, getOptionLabels } = useOptions();

  return (
    <Card>
      <Space className="fluid" direction="vertical" size="large">
        <Space split={<Pipe />}>
          <Text strong>{history.name}</Text>
          <span>
            {history.startAt}〜{history.endAt}
          </span>
        </Space>
        <Definitions>
          <Definitions.Item title="業種">
            {getOptionLabel('industry', history.industry)}
          </Definitions.Item>
          {type === 'OFFICE' && (
            <Definitions.Item title="職種">
              {getOptionLabel('occupations', history.occupation)}
            </Definitions.Item>
          )}
          <Definitions.Item title="案件詳細">
            {nl2br(history.description)}
          </Definitions.Item>
          {type === 'TECH' && (
            <Definitions.Item title="作業工程">
              {getOptionLabels('projectProcesses', history.processes)}
            </Definitions.Item>
          )}
          <Definitions.Item title={type === 'TECH' ? 'OS/言語/DB' : 'OA'}>
            {nl2br(history.environments)}
          </Definitions.Item>
          <Definitions.Item title="機器/アプリ/ツール">
            {nl2br(history.tools)}
          </Definitions.Item>
          <Definitions.Item
            title={
              <span>
                人数
                <Pipe />
                役割
              </span>
            }
          >
            <Space split={<Pipe />}>
              <span>{history.members || '-'}人</span>
              <span>{getOptionLabel('projectRoles', history.role) || '-'}</span>
            </Space>
          </Definitions.Item>
          <Definitions.Item title="規模">
            {nl2br(history.scale)}
          </Definitions.Item>
        </Definitions>
      </Space>
    </Card>
  );
}

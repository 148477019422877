import { useState } from 'react';

export default function useInput(initialValue) {
  const [input, setInput] = useState(initialValue);
  const patchInput = patch => setInput({ ...input, ...patch });
  const makePatch = key => e => {
    const isEventObject = e && e.target && e.target.value !== undefined;
    const value = isEventObject ? e.target.value : e;
    patchInput({ [key]: value });
  };
  const inputProps = key => ({ value: input[key], onChange: makePatch(key) });

  return { input, patchInput, makePatch, inputProps };
}

import { Link } from 'react-router-dom';
import Logo from '../assets/logo.png';

const renderLogo = () => (
  <img className="site-name-logo" src={Logo} alt="ATS" />
);

function SiteName({ homeLink = true }) {
  return (
    <div className="site-name">
      {homeLink ? <Link to="/">{renderLogo()}</Link> : renderLogo()}
      <span className="site-name-title">マイページ</span>
    </div>
  );
}

export default SiteName;

import React from 'react';
import { Card } from 'antd';

export default function Notification({ text, url }) {
  if (!text || !url) return null;

  return (
    <Card bordered={false} size="small">
      <a href={url} target="_blank" rel="noopener noreferrer">
        {text}
      </a>
    </Card>
  );
}

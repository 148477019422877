import { Layout, Button } from 'antd';
import SiteName from './SiteName';
import { MenuUnfoldOutlined } from '@ant-design/icons';

function Header({ onSidebarToggle, userName }) {
  return (
    <Layout.Header className="layout-header">
      <Button
        type="text"
        icon={<MenuUnfoldOutlined />}
        onClick={onSidebarToggle}
        style={{ marginRight: 18 }}
        className="mobile-only"
      />
      <SiteName />
      <div className="desktop-only">{userName}</div>
    </Layout.Header>
  );
}

export default Header;

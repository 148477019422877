import { useState, useEffect } from 'react';
import {
  Space,
  Tabs,
  Button,
  Form,
  Input,
  Select,
  Typography,
  Skeleton,
  Grid,
} from 'antd';
import Definitions from '../../components/Definitions';
import ResponsiveCard from '../../components/ResponsiveCard';
import EditableCard from '../../components/EditableCard';
import ProjectHistoryCard from './ProjectHistoryCard';
import ProjectHistoryForm from './ProjectHistoryForm';
import useInput from '../../hooks/input';
import { formLayout } from '../../helpers/form';
import nl2br from '../../helpers/nl2br';
import {
  show as showResumeApi,
  update as updateResumeApi,
} from '../../api/resume';
import useValidation from '../../hooks/validation';
import { useOptions } from '../../reducers/options';

const { Text } = Typography;
const { TextArea } = Input;

const randomString = () => Math.random().toString(32).substring(2);

const initialState = {
  human: '',
  field: '',
  type: 'TECH',
  histories: [],
};

const initialHistory = {
  startAt: '',
  endAt: '',
  industry: null,
  occupation: null,
  name: '',
  description: '',
  processes: [],
  environments: '',
  tools: '',
  members: 1,
  role: null,
  scale: '',
};

const MODE_READ = 'MODE_READ';
const MODE_WRITE = 'MODE_WRITE';

export default function WebForm({ onError }) {
  const [loading, setLoading] = useState(false);
  const [sending, setSending] = useState(false);
  const [data, setData] = useState(null);
  const [mode, setMode] = useState(MODE_READ);

  useEffect(() => {
    (async () => {
      setLoading(true);
      const response = await showResumeApi().catch(err => err.response);
      setLoading(false);

      if (response.status === 200) {
        const { histories, ...data } = response.data;
        setData({
          ...initialState,
          ...data,
          histories: histories.map(h => ({
            ...initialHistory,
            ...h,
            key: randomString(),
          })),
        });
      } else if (response.status === 404) {
        setData(initialState);
      } else {
        onError(response.status);
      }
    })();
  }, [onError]);

  const startEditing = () => setMode(MODE_WRITE);

  const submit = async value => {
    setSending(true);
    const response = await updateResumeApi(value).catch(err => err.response);
    setSending(false);

    if (response.status === 200) {
      setData(value);
      setMode(MODE_READ);
    } else {
      onError(response.status);
    }
  };

  const cancel = () => setMode(MODE_READ);

  return mode === MODE_WRITE ? (
    <EditBlock
      data={data}
      onSubmit={submit}
      onCancel={cancel}
      sending={sending}
    />
  ) : (
    <Skeleton active loading={loading}>
      <ReadBlock data={data} onStartEditing={startEditing} />
    </Skeleton>
  );
}

// 閲覧表示
function ReadBlock({ data, onStartEditing }) {
  const { lg: isDesktop } = Grid.useBreakpoint();

  if (data === null) return null;

  return (
    <Space className="fluid" direction="vertical" size="middle">
      <div className="text-right">
        <Button
          type="primary"
          ghost
          onClick={onStartEditing}
          block={!isDesktop}
        >
          編集する
        </Button>
      </div>
      <ResponsiveCard className="raised" title="概要">
        <Definitions>
          <Definitions.Item title="人物面・自己PR">
            {nl2br(data.human)}
          </Definitions.Item>
          <Definitions.Item title="専門・経験分野">
            {nl2br(data.field)}
          </Definitions.Item>
        </Definitions>
      </ResponsiveCard>
      <ResponsiveCard className="raised" title="職歴">
        <ResponsiveCard className="mb-lg" bordered>
          <Definitions>
            <Definitions.Item title="職種区分">
              {data.type === 'TECH' ? '技術系職種' : '事務系職種'}
            </Definitions.Item>
          </Definitions>
        </ResponsiveCard>
        {data.histories.length > 0 ? (
          <Tabs type="card" className="box-tabs">
            {data.histories.map((history, index) => (
              <Tabs.TabPane
                key={`${history.startAt}_${history.endAt}_${history.name}`}
                tab={`#${index + 1}`}
              >
                <ProjectHistoryCard history={history} type={data.type} />
              </Tabs.TabPane>
            ))}
          </Tabs>
        ) : (
          <div className="text-center">
            <Text disabled>職歴が設定されていません。</Text>
          </div>
        )}
      </ResponsiveCard>
    </Space>
  );
}

// バリデーションルール
const rules = [
  { name: 'histories.startAt', rules: ['required'] },
  { name: 'histories.endAt', rules: ['required'] },
  { name: 'histories.description', rules: ['required'] },
];

// 入力フォーム表示
function EditBlock({ data, sending, onSubmit, onCancel }) {
  const { input, inputProps, patchInput } = useInput(data);
  const { error, validate } = useValidation(rules);
  const { options } = useOptions();

  const addHistory = () =>
    patchInput({
      histories: [
        ...input.histories,
        { ...initialHistory, key: randomString() },
      ],
    });

  const changeType = type => {
    const typeProjectRole = options.projectRoles.find(
      role => role.type === type
    );

    patchInput({
      type,
      histories: input.histories.map(history => ({
        ...history,
        role: typeProjectRole.id,
      })),
    });
  };

  const updateHistory = (key, val) =>
    patchInput({
      histories: input.histories.map(h =>
        h.key === key ? { ...h, ...val } : h
      ),
    });

  const editHistories = (key, action) => {
    // 追加の場合
    if (action === 'add') return addHistory();

    // 以下、削除の場合

    const index = input.histories.findIndex(h => h.key === key);
    error.remove([
      `histories.${index}.startAt`,
      `histories.${index}.endAt`,
      `histories.${index}.description`,
    ]);

    patchInput({ histories: input.histories.filter(h => h.key !== key) });
  };

  const handleFinish = () => validate(input, () => onSubmit(input));

  return (
    <Form
      onFinish={handleFinish}
      {...formLayout}
      labelCol={{ span: 6 }}
      wrapperCol={{ span: 18 }}
    >
      <Space className="fluid" direction="vertical" size="middle">
        <ResponsiveCard className="raised" title="概要">
          <Form.Item label="人物面・自己PR">
            <TextArea autoSize={{ minRows: 3 }} {...inputProps('human')} />
          </Form.Item>
          <Form.Item label="専門・経験分野" style={{ marginBottom: 0 }}>
            <TextArea autoSize={{ minRows: 3 }} {...inputProps('field')} />
          </Form.Item>
        </ResponsiveCard>

        <ResponsiveCard className="raised" title="職歴">
          <ResponsiveCard bordered className="mb-lg">
            <Form.Item label="職種区分" style={{ marginBottom: 0 }}>
              <Select value={input.type} onChange={changeType}>
                <Select.Option value="TECH">技術系職種</Select.Option>
                <Select.Option value="OFFICE">事務系職種</Select.Option>
              </Select>
            </Form.Item>
          </ResponsiveCard>

          {error.hasAny() && (
            <div className="mb-lg">
              {error.renderList(
                input.histories.reduce(
                  (prev, _, index) => [
                    ...prev,
                    {
                      name: `histories.${index}.startAt`,
                      label: `職歴 #${index + 1} 開始月`,
                    },
                    {
                      name: `histories.${index}.endAt`,
                      label: `職歴 #${index + 1} 終了月`,
                    },
                    {
                      name: `histories.${index}.description`,
                      label: `職歴 #${index + 1} 担当業務`,
                    },
                  ],
                  []
                )
              )}
            </div>
          )}

          {input.histories.length > 0 ? (
            <Tabs
              type="editable-card"
              className="box-tabs"
              onEdit={editHistories}
            >
              {input.histories.map((history, index) => (
                <Tabs.TabPane key={history.key} tab={`#${index + 1}`}>
                  <ProjectHistoryForm
                    index={index}
                    error={error}
                    type={input.type}
                    value={history}
                    onChange={val => updateHistory(history.key, val)}
                  />
                </Tabs.TabPane>
              ))}
            </Tabs>
          ) : (
            <Button type="dashed" block onClick={addHistory}>
              職歴を追加する
            </Button>
          )}
        </ResponsiveCard>

        <EditableCard.Footer onCancel={onCancel} loading={sending} />
      </Space>
    </Form>
  );
}
